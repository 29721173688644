/* eslint-disable global-require */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import moment from 'moment';

import { useLazyGetGrowerForestImagesQuery } from '../configuration/store/api';
import { transformGrowerForestImagesData } from './helper';
import { RoutesPath } from '../../routing/RoutesPath';

import './styles.css';
import ModalViewImage from './components/modal-view-image';

type Props = {
}

const SpeciesCard = ({data, species, handleShowViewImageModal, setSelectedViewImage}: any) => {
  const navigate = useNavigate();
  
  return (
    <button 
      type='button'
      className='remove-btn-styles d-flex align-items-center flex-row gap-4 bordered-grey p-4 mb-4'
      style={{width: '60%'}}
      onClick={() => {
        handleShowViewImageModal();
        setSelectedViewImage(data.url);
      }}
      >
      <img 
        src={data.url} 
        alt={data.url} 
        key={`${data.url}`} 
        style={{width: '100px', height: '100px', borderRadius: '10px'}} 
        />
      <div className='d-flex flex-column flex-start justify-content-between h-100'>
        <div className='d-flex flex-row gap-4'>
          {
            data.height && data.height > 0 ? (
              <h3 className='m-0 text-xl font-bold mb-4 inline-block py-1 px-4' style={{backgroundColor: '#F0F0F0', borderRadius: 5}}>Height: {data.height}m</h3>
            ) : null
          }
          {
            data.width && data.width > 0 ? (
              <h3 className='m-0 text-xl font-bold mb-4 inline-block py-1 px-4' style={{backgroundColor: '#F0F0F0', borderRadius: 5}}>Width: {data.width}cm</h3>
            ) : null
          }
        </div>
        <div className='d-flex flex-row gap-4'>
          {
            data.radius && data.radius > 0 ? (
              <h3 className='m-0 text-xl font-bold mb-4 inline-block py-1 px-4' style={{backgroundColor: '#F0F0F0', borderRadius: 5}}>Circumference: {data.radius}cm</h3>
            ) : null
          }
        </div>
      </div>
    </button>
  )
}

const GrowerForestSpeciesUpdatesPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const {state}: any = useLocation();

  const [showViewImageModal, setShowViewImageModal] = useState(false);
  const [selectedViewImage, setSelectedViewImage] = useState('');

  return (
    <div className='blank-page-view py-8 pb-12 px-12'>

      <div className='back-header-btn'>
        <button 
          type='button' 
          className='p-2 back-left-arrow-btn-wrap'
          onClick={()=>{navigate(-1)}}
        >
          <div className='back-left-arrow-btn' /> 
        </button>
      </div>

      <h2 className='text-2xl font-bold mt-4 mb-10'>SPECIES UPDATE ({state?.species})</h2>

      {
        Object.keys(state?.data || {}).length === 0 ? (
          <div className='flex justify-center items-center h-screen'>
            <h3 className='text-xl font-bold'>No images found</h3>
          </div>
        ) : (
          <h3 className='text-xl font-bold'>
            {moment().format('DD-MM-YYYY')}
          </h3>
        )
      }

      {
        Object.keys(state?.data || {}).map((species: any) => (
          <div key={species}>
            <SpeciesCard 
              data={state?.data[species]} 
              species={species} 
              handleShowViewImageModal={() => setShowViewImageModal(true)}
              setSelectedViewImage={setSelectedViewImage}
            />
          </div>
        ))
      }

      <ModalViewImage 
        show={showViewImageModal}
        onHide={() => setShowViewImageModal(false)}
        imageUrl={selectedViewImage}
      />
      
    </div>
  )
}

export default GrowerForestSpeciesUpdatesPage
/* eslint-disable global-require */
/* eslint-disable react/no-unstable-nested-components */
