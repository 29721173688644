import { count } from "console"

export const contractsData = [
  {
    name: 'Carbon Credits Agreement',
    url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Contracts/Carbon%20Credits%20Agreement.pdf',
    date: ''
  },
  {
    name: 'Farmer Donor Agreement',
    url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Contracts/Farmer%20Donor%20Agreement.pdf',
    date: ''
  },
  {
    name: 'Donor Implementation Partner Agreement',
    url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Contracts/Donor%20Implementation%20Partner%C2%A0Agreement.pdf',
    date: ''
  },
]

export const farmerDetailsData = [
  {
    name: 'List of Farmer',
    url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Farmer_Details/List%20of%20Farmer.xlsx',
    date: ''
  },
  {
    name: `Farmer's Data`,
    url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Farmer_Details/Farmers_Data.pdf',
    date: ''
  },
]

export const donationsData = [
  {
    name: 'Form10 BE_AAATN2405L05241000104',
    url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Donation_Certificates/Form10BE_AAATN2405L05241000104%20-%20Q1.pdf',
    date: ''
  },
  // {
  //   name: 'Form10 BE_AAATN2405L05241000104 - Q2',
  //   url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Donation_Certificates/Form10BE_AAATN2405L05241000104%20-%20Q2.pdf',
  //   date: ''
  // },
  // {
  //   name: 'Form10 BE_AAATN2405L05241000104 - Q3',
  //   url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Donation_Certificates/Form10BE_AAATN2405L05241000104%20-%20Q3.pdf',
  //   date: ''
  // },
  // {
  //   name: 'Form10 BE_AAATN2405L05241000104 - Q4',
  //   url: 'https://mynzo-staging.s3.ap-south-1.amazonaws.com/dasboard-contracts/M/Donation_Certificates/Form10BE_AAATN2405L05241000104%20-%20Q4.pdf',
  //   date: ''
  // }
]

export const receiptsData = [
  {
    name: 'Receipt1',
    url: 'https://www.google.com',
    date: '03-03-2023'
  },
  {
    name: 'Receipt2',
    url: 'https://www.google.com',
    date: '04-04-2023'
  },
  {
    name: 'Receipt3',
    url: 'https://www.google.com',
    date: '05-05-2023'
  },
  {
    name: 'Receipt4',
    url: 'https://www.google.com',
    date: '06-06-2023'
  },
  {
    name: 'Receipt5',
    url: 'https://www.google.com',
    date: '07-07-2023'
  },
  {
    name: 'Receipt6',
    url: 'https://www.google.com',
    date: '08-08-2023'
  },
]

export const forestPlantationChartData = {
  series: [
    {name: 'Acres Purchased', data: [10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8, 10.8]},
    {name: 'Acres Planted', data: [3.9, 4.2, 4.6, 5.5, 5.9, 6.2, 6.2, 7.35, 9.25, 9.75, 10.8]}
  ],
  categories: ['Jan 2024', 'Feb 2024', 'Mar 2024', 'Apr 2024', 'May 2024', 'Jun 2024', 'Jul 2024', 'Aug 2024', 'Sep 2024', 'Oct 2024', 'Nov 2024']
}

export const forestDonutContributionChartData = [
  {name: 'MALES', count: 110},
  {name: 'FEMALES', count: 10}
]

export const forestTotalForestAreaChartData = [
  {name: 'AREA', count: 120}
]

export const forestTotalTreesPlantedChartData = [
  {name: 'TREES PLANTED', count: 1000000}
]

export const forestTotalRecapturedChartData = [
  {name: 'RECAPTURED', count: 1000000}
]

export const forestDummyData = {
  "totalAcresPurchased": 6,
  "recaptureData": {
      "recaptureToDate": 736000,
      "recapturePerYear": 2830 ,
      "recaptureThisYear": 3280,
      "totalRecapturedPerSapling": 16.6
  },
  "totalForestUpdates": [
      {
          "data": [
              {
                  "images": [
                      "https://mynzo.s3.ap-south-1.amazonaws.com/forest-plantation-updates/PlotID42361.jpeg",
                      "https://mynzo.s3.ap-south-1.amazonaws.com/forest-plantation-updates/PlotID84185.jpeg",
                      "https://mynzo.s3.ap-south-1.amazonaws.com/forest-plantation-updates/PlotID89028.jpeg",
                      "https://mynzo.s3.ap-south-1.amazonaws.com/forest-plantation-updates/A0220105-1.jpg",
                      "https://mynzo.s3.ap-south-1.amazonaws.com/forest-plantation-updates/D1130076-1.jpg"
                  ]
              }
          ],
          "date": "2023-10-30",
          "type": "PLANTED",
          "area": 6
      },
  ],
  "forestData": {
      "1": {
          "totalForestArea": 6,
          "forestName": "Forest-1: Sewers Construction - Bhubaneshwar",
          "totalRecaptured": 736000,
          "totalForestAreaPlanted": 6,
          "totalTreesPlanted": 2029,
          "state": "Odisha",
          "district": "India",
          "villege": "Odisha",
          "boundry": "[[85.80730909725588, 20.35786150233253],[85.80696452716056, 20.35734654182507],[85.80692598488457, 20.3563283317934],[85.80642443344769, 20.355518494988],[85.80606524212779, 20.3551748905277],[85.80580387476651, 20.35497194436355],[85.80557918730321, 20.35468348434143],[85.80796069705045, 20.35450746667589],[85.80821893868807, 20.35561993333827],[85.80852452787002, 20.35684886368769],[85.80852282269191, 20.35712160672506],[85.80813284845823, 20.35746290596876],[85.80768692386917, 20.35776566226225],[85.80730909725588, 20.35786150233253]]",
          "recentUpdates": [
              {
                  "data": [
                      {
                          "images": [
                              "https://mynzo.s3.ap-south-1.amazonaws.com/forest-plantation-updates/PlotID42361.jpeg",
                              "https://mynzo.s3.ap-south-1.amazonaws.com/forest-plantation-updates/PlotID84185.jpeg",
                              "https://mynzo.s3.ap-south-1.amazonaws.com/forest-plantation-updates/PlotID89028.jpeg",
                              "https://mynzo.s3.ap-south-1.amazonaws.com/forest-plantation-updates/A0220105-1.jpg",
                              "https://mynzo.s3.ap-south-1.amazonaws.com/forest-plantation-updates/D1130076-1.jpg"
                          ]
                      }
                  ],
                  "date": "2021-04-01",
                  "type": "PLANTED",
                  "area": 6
              }
          ]
      },
  },
  "totalBoundry":"[[85.80730909725588, 20.35786150233253],[85.80696452716056, 20.35734654182507],[85.80692598488457, 20.3563283317934],[85.80642443344769, 20.355518494988],[85.80606524212779, 20.3551748905277],[85.80580387476651, 20.35497194436355],[85.80557918730321, 20.35468348434143],[85.80796069705045, 20.35450746667589],[85.80821893868807, 20.35561993333827],[85.80852452787002, 20.35684886368769],[85.80852282269191, 20.35712160672506],[85.80813284845823, 20.35746290596876],[85.80768692386917, 20.35776566226225],[85.80730909725588, 20.35786150233253]]"
}