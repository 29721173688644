import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useIntl } from "react-intl"
import AccordionDivOne from "./components/accordion-div-1"
import AccordionDivTwo from "./components/accordion-div-2"
import AccordionDivThree from "./components/accordion-div-3"
import AccordionDivFour from "./components/accordion-div-4"
import { HeaderInfoCard } from "./components/header-info-card-2"
import { RootState } from "../../../store/store"
import { usePostManualYearCumulativeSurveyMutation } from "../store/api"
import { getCountryList } from "./api"
import { setCountryList } from "../../../store/slices/masterList"
import { ManualYearCumulativeSurveyRequestType } from "../store/type"
import { clearAllScopeSurveyFormFields, setAllSurveyFormFieldsBySurveyInputData } from "../../../store/slices/forms/scopeSurveyForm"
import { validateSurveyQuestions } from "./validation"
import { useGetProfileDetailsQuery } from "../../../routing/store/api"
import { updateProfile } from "../../../store/slices/profile"
import { updateNotification } from "../../../store/slices/notifier"
import { NotificationType } from "../../../../_metronic/partials/components/types"
import LockWrapper from "../../../../_metronic/partials/components/lock-wrapper/LockWrapper"
import { useSetOfficeDetailsWithStatesAndCityLists } from "./useSetOfficeDetailsWithStatesAndCity"

const SurveyQuestionsPage = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const formState = useSelector((state: RootState) => state.scopeSurveyForm)
  const [isValid, setIsValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")
  const [postSurvey, {isSuccess: isSuccessPostSurvey, isError: isErrorPostSurvey}] = usePostManualYearCumulativeSurveyMutation()
  const { data: profileDetails, refetch: refetchUserProfileData } = useGetProfileDetailsQuery()
  const {surveyBasedData, lockedScreens, surveyInputData} = useSelector((state: RootState) => state.profile)
  const countryList = useSelector((state: RootState) => state.masterList.countryList);
  const [setOfficeElectricityConsumptionDetails] = useSetOfficeDetailsWithStatesAndCityLists();

  useEffect(() => {
    dispatch(setAllSurveyFormFieldsBySurveyInputData(surveyInputData))
  }, [surveyInputData])

  useEffect(() => {
    if (
      countryList?.length > 0 && 
      formState?.officeDetails?.length > 0 &&
      formState?.officeDetails?.[0]?.stateList?.length === 0
    ) {
      setOfficeElectricityConsumptionDetails(formState?.officeDetails)
    }
  }, [countryList, formState])

  useEffect(() => {
    if (isSuccessPostSurvey) {
      dispatch(
        updateNotification({
          message: 'Survey submitted successfully',
          type: NotificationType.SUCCESS
        })
      )
    }
  }, [isSuccessPostSurvey])

  useEffect(() => {
    if (isErrorPostSurvey) {
      dispatch(
        updateNotification({
          message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
          type: NotificationType.ERROR,
        })
      )
    }
  }, [isErrorPostSurvey])

  useEffect(() => {
    if (profileDetails) {
      dispatch(updateProfile(profileDetails))
    }
  }, [profileDetails])

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countries = await getCountryList()
        dispatch(setCountryList(countries.data))
      } catch (error) {
        console.error('Error fetching countries:', error)
      }
    }
    fetchCountries()
  }, [dispatch])

  const handleSubmit = async () => {
    // Reset validation states
    setIsValid(true)
    setErrorMessage("")

    try {
      // Transform form data to API format
      const payload: ManualYearCumulativeSurveyRequestType = {
        office_electricity_consumption_details: formState.officeDetails.map(office => ({
          geo_id: office.country.id || 73,
          country: office.country,
          state: office.state,
          city: office.city,
          area_sq_ft: office.totalOfficeSquareFootage || 0,
          no_of_employees: office.numberOfEmployeesInOffice || 0,
          avg_wfh_days_per_week_per_employee: office.averageWFHDaysPerWeek || 0,
          is_igbc_certified: office.isIGBCCertified === 'yes',
          is_renewable_energy_sourced: office.isRenewableEnergySourced === 'yes'
        })).filter((office: any) => office.numberOfEmployeesInOffice === 0),
        employee_commute_details: {
          no_of_company_owned_vehicles: formState.numberOfCompanyVehicles || 0,
          avg_km_per_day_per_vehicle: formState.averageCommuteDistancePerDay || 0,
          no_of_employee_using_pvt_vehicles: formState.numberOfEmployeesUsingPrivateVehicles || 0,
          avg_km_per_employee_per_day: formState.averageCommuteDistancePerEmployee || 0
        },
        light_traveler_details: {
          no_of_employee_in_light_travels: formState.numberOfLightTravelers || 0,
          local_avg_round_trip_per_person_per_quarter: formState.lightTravelersLocalTrips || 0,
          local_travel_class: String(formState.lightTravelersLocalClass || 'Economy'),
          medium_avg_round_trip_per_person_per_quarter: formState.lightTravelersMediumTrips || 0,
          medium_travel_class: String(formState.lightTravelersMediumClass || 'Economy'),
          long_avg_round_trip_per_person_per_quarter: formState.lightTravelersLongTrips || 0,
          long_travel_class: String(formState.lightTravelersLongClass || 'Economy')
        },
        heavy_traveler_details: {
          no_of_employee_in_heavy_travels: formState.numberOfHeavyTravelers || 0,
          local_avg_round_trip_per_person_per_quarter: formState.heavyTravelersLocalTrips || 0,
          local_travel_class: String(formState.heavyTravelersLocalClass || 'Economy'),
          medium_avg_round_trip_per_person_per_quarter: formState.heavyTravelersMediumTrips || 0,
          medium_travel_class: String(formState.heavyTravelersMediumClass || 'Economy'),
          long_avg_round_trip_per_person_per_quarter: formState.heavyTravelersLongTrips || 0,
          long_travel_class: String(formState.heavyTravelersLongClass || 'Economy')
        }
      }

      const isValidForm = validateSurveyQuestions(payload)

      if (!isValidForm.isValid) {
        setIsValid(false)
        setErrorMessage(isValidForm.errorMessage)
        return
      }

      // Submit the data
      const response = await postSurvey(payload).unwrap()
      await refetchUserProfileData()
      
    } catch (error) {
      console.error('Error submitting survey:', error)
      setIsValid(false)
      setErrorMessage("Failed to submit survey. Please try again.")
    }
  }

  return (
    <LockWrapper isLocked={lockedScreens.surveyQuestionsPage} description="This page displays the survey questions for your company to calculate your company's Scope 1, Scope 2 and Scope 3 emissions. For more details, contact support.">
      <div className="blank-page-view pt-6 pb-16 px-8">
        <div className='p-0 m-0'>
          <h1 className='m-0' style={{fontWeight: 600}}>SURVEY QUESTIONS</h1>
          <p className='mt-4' style={{fontSize: 14, fontWeight: 500}}>
            Answer the below questions and get your company&apos;s annual emissions profile
          </p>
        </div>
        <div className='mt-4 d-flex justify-content-between'>
          <HeaderInfoCard
            label='SCOPE 1 EMISSIONS'
            value={surveyBasedData?.totalEmissions?.scope1 || '--'}
            unit='kgCO2'
          />
          <HeaderInfoCard
            label='SCOPE 2 EMISSIONS'
            value={surveyBasedData?.totalEmissions?.scope2 || '--'}
            unit='kgCO2'
          />
          <HeaderInfoCard
            label='SCOPE 3 EMISSIONS'
            value={surveyBasedData?.totalEmissions?.scope3 || '--'}
            unit='kgCO2'
          />
        </div>
        <div className='mt-14'>
          <div className='mb-8'>
            <AccordionDivOne 
              data={{
                title: 'Office Energy Consumption Details', 
                status: surveyInputData?.officeElectricityConsumptionDetails ? 'completed' : formState.officeElectricityStatus
              }} 
            />
          </div>
          <div className='mb-8'>
            <AccordionDivTwo 
              data={{
                title: 'Employee Office Commute', 
                status: surveyInputData?.employeeCommuteDetails ? 'completed' : formState.employeeCommuteStatus
              }} 
            />
          </div>
          <div className='mb-8'>
            <AccordionDivThree 
              data={{
                title: 'Professional Air Commute - Light (Less Than 1 Trip Per Month)', 
                status: surveyInputData?.lightTravelerDetails ? 'completed' : formState.lightTravelersStatus
              }} 
            />
          </div>
          <div className='mb-8'>
            <AccordionDivFour 
              data={{
                title: 'Professional Air Commute - Heavy (More Than 1 Trip Per Month)', 
                status: surveyInputData?.heavyTravelerDetails ? 'completed' : formState.heavyTravelersStatus
              }} 
            />
          </div>

          {/* <div className='d-flex align-items-end justify-content-end mt-10'>
            {!isValid && (
              <p className="text-danger mt-2 mb-0 mx-6" style={{fontSize: '14px'}}>
                {errorMessage}
              </p>
            )}
            <button
              type='button'
              className='remove-button-styles fs-5'
              style={{
                borderRadius: 10,
                color: 'white',
                backgroundColor: '#0491BD',
                border: 'none',
                padding: '8px 30px',
                boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                fontWeight: 500
              }}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div> */}
        </div>
      </div>
    </LockWrapper>
  )
}

export default SurveyQuestionsPage
