/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint no-array-constructor: "error" */
import {FC, useCallback, useEffect, useRef, useState} from 'react'
import { useSelector } from 'react-redux'
import ApexCharts, {ApexOptions} from 'apexcharts'
import { toPng } from 'html-to-image'
import {
  useLazyGetEmissionTrendsQuery,
} from './store/api'
import {getCSS, getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../_metronic/partials'
import { getLocalStorageItem } from '../../utils/storage'
import { convertDailyToMonthlyWithActivities, getMonthFormat, getYYYYDDMMFormat } from '../../utils/dateFormatter'
import { DateRangeType } from '../../../_metronic/partials/components/types'
// import '../../../_metronic/partials/components/styles.css'
import { RootState } from '../../store/store'
import { commaSeparatedFormat, convertMonthYearToDate } from '../../utils/helpers'
import { DownloadIcon } from '../../../_metronic/assets/icons'
import ModalDownloadChart, { ChartType} from '../../../_metronic/partials/components/modal-download-charts/modal-download-chart'
import { FilterDataTypeToggleEnum } from '../../store/slices/filters'

interface SeriesData {
  name: string;
  data: number[];
}

const CHART_LABEL_COLORS = ['#100E14', '#685097', '#A687CA', '#C4B4E2', '#EDEBF3'];

const getCopyChartOptions = (chartOptions: ApexOptions, copychartref: any) => {
  const copyChartOptions: ApexOptions = {...chartOptions};

  // if (copyChartOptions?.legend) copyChartOptions.legend.show = false;
  // if (copyChartOptions?.chart?.height) copyChartOptions.chart.height = '250px';

  const copychart = new ApexCharts(copychartref.current, copyChartOptions);
  return copychart;
}

function getChartOptions(
  _height: number, 
  ): ApexOptions {

  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')

  return {
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: 310,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: true,
      onItemClick: {
        toggleDataSeries: false,
      },
      // onItemHover: {
      //   highlightDataSeries: false,
      // },
      markers: {
        width: 15,
        height: 15,
        strokeColor: borderColor,
        strokeWidth: 0,
        offsetX: 0,
        offsetY: 0,
        radius: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      colors: CHART_LABEL_COLORS,
      opacity: 1,
    },
    xaxis: {
      axisBorder: {
        show: false,
        color: '#d8d8d8',
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      tickAmount: 4,
      title: {
        text: 'kgCO2',
        style: {
          color: labelColor,
          fontSize: '12px',
        },
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    tooltip: {
      shared: true, 
      intersect: false,
      x: {
        formatter: (val) => `${val} (kgCO2)`
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    colors: CHART_LABEL_COLORS,  
    grid: {
      borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}

const ActivityEmissionStackForAllMonth: FC<any> = (props) => {
  // apis
  const [getTotalEmission, {data: totalEmissionData}] = useLazyGetEmissionTrendsQuery()
  // state
  const {
    filterDate, 
    filterYear, 
    filterExtrapolation
  } = useSelector((state: RootState) => state.filters)
  const {profile} = useSelector((state: RootState) => state.profile)
  const [categories, setCategories] = useState<string[]>([])
  const [series, setSeries] = useState<SeriesData[]>([])

  const chartref = useRef<HTMLDivElement | null>(null)
  const copychartref = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const labelColor = getCSSVariableValue('--bs-gray-500')

  const [downloadChartModalShow, setDownloadChartModalShow] = useState<boolean>(false);
  const [chartModalData, setChartModalData] = useState<any>({title: "Emission By Activity"});

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
  }, [])

  useEffect(() => {
    // const liveOnDate = new Date(getLocalStorageItem('live-on'))
    // const currentDate = new Date()
    if (filterDate.startDate !== '') {
      const reqObject1 = {
        // fromDate: getYYYYDDMMFormat(liveOnDate),
        // toDate: getYYYYDDMMFormat(currentDate),
        fromDate: filterDate.startDate, 
        toDate: filterDate.endDate,
        is_all: 'true',
        monthly: 'true',
        group_activities: 'true',
      }
      getTotalEmission(reqObject1)
    }
  }, [filterExtrapolation, filterDate, filterYear])

  const liveOnDate = new Date(getLocalStorageItem('live-on'))
  const totalMonthlyData = convertDailyToMonthlyWithActivities(totalEmissionData || [], liveOnDate, filterYear, profile?.organization?.isWorkshopOrg)
  const seriesData: number[] = []

  const totalSeriesData: number[] = new Array(12).fill(0)
  const totalSeriesDataCopy: number[] = new Array(12).fill(0)
  const totalActivitiesSeriesData: any[] = new Array(12).fill(null)

  const seriesTemp: SeriesData[] = [{
    name: 'Home',
    data: []
  }, {
    name: 'Office',
    data: []
  }, {
    name: 'Commute',
    data: []
  }, {
    name: 'Other',
    data: []
  }, {
    name: 'Flight',
    data: []
  }]

  useEffect(() => {
    const categoriesData: string[] = []
    if (totalEmissionData) {
      totalMonthlyData?.forEach((itm, index) => {
        // console.log('monthlyData..',itm)
        // eslint-disable-next-line no-sequences, no-unused-expressions
        const totHeight = itm.emission - (seriesData[index] || 0) 
        totalSeriesData[index] = (totHeight < 0) ? 0 : totHeight
        totalSeriesDataCopy[index] = itm.emission
    
        totalActivitiesSeriesData[index] = itm.activities
    
        const newDate = convertMonthYearToDate(itm.monthYear)
        const monthYearString = getMonthFormat(itm.monthYear);
        categoriesData.push(monthYearString)
      })

      seriesTemp.forEach(category => {
        totalMonthlyData?.forEach(dataPoint => {
          // if (category.data) {
          const activity = dataPoint?.activities.find((act:any) => act.name === category.name);
          const emission = activity ? activity.emission : 0;
          category.data.push(emission);
          // }
        });
      });
        setCategories(categoriesData)
        setSeries(seriesTemp)
    }
  }, [totalEmissionData])

  const refreshMode = useCallback(() => {
    // console.log(series, categories)
    if (!chartref.current) {
      return undefined
    }
    const height = parseInt(getCSS(chartref.current, 'height'), 10)
    const values = getChartOptions(height)
    const options = {
      ...values,
      series,
      xaxis: {...values.xaxis, categories, tickAmount: 5},
      ...({
        chart: {
          ...values.chart,
          animations: {
            enabled: false,
          },
        },
      }),
    }

    options.yaxis = {
      ...options.yaxis,
      labels: {
        formatter: (value:any) => `${commaSeparatedFormat(parseFloat(value?.toFixed(1)))}`,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    }

    const chart = new ApexCharts(chartref.current, options)
    // copy chartOptions for modal download preview
    const copychart = getCopyChartOptions(options, copychartref);
    if (chart) {
      chart.render()
      copychart.render()
    }
    return chart
  }, [series, categories, downloadChartModalShow])

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartref, mode, refreshMode])

  return (
    <div className='mt-3 bordered-grey'>
      {/* begin::Header */}
      <div className='card-header d-flex justify-content-between border-0 px-4 pt-3'>
        <h2 className='m-0'>EMISSION BY ACTIVITY</h2>
        <button 
          type='button' 
          className='remove-btn-styles download-report-btn p-0 mx-0'
          onClick={() => setDownloadChartModalShow(true)}
          // style={{display: 'none'}}
          >
          <DownloadIcon color='#5CADC9'/> 
        </button>
      </div>
      {/* end::Header */}

      <div className='card-body pt-4'>
        <div ref={chartref} id='kt_charts_widget_3_chart' />
      </div>

      <div ref={copychartref} style={{display: 'none'}} />

      <ModalDownloadChart 
        show={downloadChartModalShow} 
        onHide={() => setDownloadChartModalShow(false)}
        chartref={copychartref}
        chartType={ChartType.ACTIVITIES_BAR}
        data={chartModalData}
        />
    </div>
  )
}

export {ActivityEmissionStackForAllMonth}

/* eslint no-array-constructor: "error" */

