import React, {useState, useEffect} from 'react'
import moment from 'moment'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl';

import { RootState, useAppDispatch } from '../../../store/store'
import { updateNotification } from '../../../store/slices/notifier';
import { NotificationType } from '../../../../_metronic/partials/components/types';
import { IconMinimizePurple } from '../../../../_metronic/assets/icons';
import { InputNumber, InputText } from '../../../ui/components/input-text';
import { Dropdown } from '../../../ui/components/dropdown';
import { useAddReductionInitiativesMutation, useUpdateReductionInitiativesMutation } from '../store/api';

import '../styles.css'

type FORM_FIELDS = 'name' | 'targetReduction' | 'emissionsType' | 'lead' | 'targetYear' 

type FormState = {
  name: string,
  targetReduction: number,
  emissionsType: string,
  lead: string,
  targetYear: number
}

export default function ModalAddReductionInitiative(props:any) {
  const { onHide } = props

  const intl = useIntl()

  const dispatch = useAppDispatch()
  const {surveyBasedData} = useSelector((state:RootState) => state.profile)

  const [addReductionInitiatives, {
    isLoading: isLoadingAddReductionInitiatives, 
    isSuccess: isSuccessAddReductionInitiatives,
    isError: isErrorAddReductionInitiatives
  }] = useAddReductionInitiativesMutation()

  const [updateReductionInitiatives, {
    isLoading: isLoadingUpdateReductionInitiatives, 
    isSuccess: isSuccessUpdateReductionInitiatives,
    isError: isErrorUpdateReductionInitiatives
  }] = useUpdateReductionInitiativesMutation()

  const [formState, setFormState] = useState<FormState>({
    name: '',
    targetReduction: 0,
    emissionsType: '',
    lead: '',
    targetYear: moment().year()
  })
  const [totalScopeEmission, setTotalScopeEmission] = useState(0)
  const [emissionPercentage, setEmissionPercentage] = useState(0)
  const [isValidName, setIsValidName] = useState(true)
  const [isValidTargetReduction, setIsValidTargetReduction] = useState(true)
  const [isValidEmissionsType, setIsValidEmissionsType] = useState(true)
  const [isValidLead, setIsValidLead] = useState(true)
  const [isValidTargetYear, setIsValidTargetYear] = useState(true)

  const handleFormChange = (field: FORM_FIELDS, value:string | number) => {
    setFormState({...formState, [field]: value})
    if (field === 'name' && String(value).length > 5) {
      setIsValidName(true)
    } else if (field === 'emissionsType' && value) {
      setIsValidEmissionsType(true)
    } else if (field === 'lead' && String(value).length > 1) {
      setIsValidLead(true)
    } else if (field === 'targetReduction' && Number(value) > 0) {
      setIsValidTargetReduction(true)
    } else if (field === 'targetYear' && String(value).length === 4) {
      setIsValidTargetYear(true)
    }
  }

  useEffect(() => {
    if (formState.emissionsType === 'scope_3' && surveyBasedData?.scope3CategoryData?.length > 0) {
      setTotalScopeEmission((surveyBasedData?.scope3CategoryData[0]?.totalEmissions || 0)/1000)
    } else if (formState.emissionsType === 'scope_1' && surveyBasedData?.scope1CategoryData?.length > 0) {
      setTotalScopeEmission((surveyBasedData?.scope1CategoryData[0]?.totalEmissions || 0)/1000)
    } else if (formState.emissionsType === 'scope_2' && surveyBasedData?.scope2CategoryData?.length > 0) {
      setTotalScopeEmission((surveyBasedData?.scope2CategoryData[0]?.totalEmissions || 0)/1000)
    } else {
      setTotalScopeEmission(0)
    }
  }, [surveyBasedData, formState.emissionsType])

  const isValidFormField = () => {
    let isValid = true
    if (!formState.name || formState.name === '' || formState?.name?.length < 2) {
      setIsValidName(false)
      isValid = false
    } else {
      setIsValidName(true)
    }
    if (!formState.targetReduction || formState.targetReduction === 0) {
      setIsValidTargetReduction(false)
      isValid = false
    } else {
      setIsValidTargetReduction(true)
    }
    if (!formState.emissionsType) {
      setIsValidEmissionsType(false)
      isValid = false
    } else {
      setIsValidEmissionsType(true)
    }
    if (!formState.lead || formState.lead === '' || formState?.lead?.length < 2) {
      setIsValidLead(false)
      isValid = false
    } else {
      setIsValidLead(true)
    }
    if (!formState.targetYear || formState.targetYear === 0 || formState.targetYear < moment().year() || formState.targetYear > moment().year() + 100 || String(formState.targetYear)?.length !== 4) {
      setIsValidTargetYear(false)
      isValid = false
    } else {
      setIsValidTargetYear(true)
    }
    return isValid
  }

  const handleClearState = () => {
    setFormState({
      name: '',
      targetReduction: 0,
      emissionsType: '',
      lead: '',
      targetYear: moment().year()
    })
  }

  const handleSubmit = () => {
    if (isValidFormField()) {
      onHide()
      addReductionInitiatives({
        name: formState.name,
        lead: formState.lead,
        target_date: String(formState.targetYear || String(moment().year())),
        target_reduction: (Number(formState.targetReduction || 0)*1000).toFixed(2),
        type: formState.emissionsType
      })
      handleClearState()
    }
  }

  useEffect(() => {
    if (isSuccessAddReductionInitiatives && !isLoadingAddReductionInitiatives) {
      onHide()
      dispatch(
        updateNotification({
          message: 'Reduction initiative added successfully', 
          type: NotificationType.SUCCESS
        })
      )
      handleClearState()
    }
  }, [isSuccessAddReductionInitiatives, isLoadingAddReductionInitiatives])

  useEffect(() => {
    if (isErrorAddReductionInitiatives && !isLoadingAddReductionInitiatives) {
      onHide()
      dispatch(
        updateNotification({
          message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
          type: NotificationType.ERROR,
        })
      )
    }
  }, [isErrorAddReductionInitiatives, isLoadingAddReductionInitiatives])

  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Body>
        <div style={{borderBottom: '1px solid #E0E0E0', paddingBottom: '10px'}}>
          <div className='d-flex align-items-center'>
            <IconMinimizePurple />
            <h2 className='m-0 mx-2 modal-header-title'>REDUCTION INITIATIVE</h2>
          </div>
          <button 
            type='button' 
            className='remove-btn-styles modal-header-cross-btn' 
            onClick={onHide}
            style={{height: '26px', width: '26px', borderRadius: '50%', backgroundColor: 'lightgray', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            >
            <i className="bi bi-x-lg" style={{fontSize: '16px', color: 'black'}} />
          </button>
        </div>
        <div className='d-flex flex-column align-items-start justify-content-between mb-5 mt-6'>
          <InputText 
            label='REDUCTION INITIATIVE' 
            placeholder='Enter Reduction Initiative Name e.g. Solar Panel Installation' 
            value={formState.name as string}
            onChange={(value:any) => {handleFormChange('name', value)}}
            containerStyle={{width: '100%'}}
          />
          {
            !isValidName && (
              <p style={{color: 'red', fontSize: 13, marginBottom: 0, marginTop: 5}}>Please enter a valid name</p>
            )
          }
        </div>
        <div className='d-flex justify-content-between gap-6 mb-5 mt-6'>
          <div style={{width: '100%'}}>
            <Dropdown
              label="SCOPE"
              options={[
                {
                  label: 'Scope 1',
                  value: 'scope_1'
                },
                {
                  label: 'Scope 2',
                  value: 'scope_2'
                },
                {
                  label: 'Scope 3',
                  value: 'scope_3'
                }
              ]}
              onChange={(v) => handleFormChange('emissionsType', v)}
              selectedValue={formState.emissionsType as string}
              placeholder='Select Emissions Type'
              containerStyle={{width: '100%'}}
            />
            {
              !isValidEmissionsType && (
                <p style={{color: 'red', fontSize: 13, marginBottom: 0, marginTop: 5}}>Please select a valid scope</p>
              )
            }
          </div>
          <div style={{width: '100%'}}>
            <InputText 
              label='LEAD' 
              placeholder='Enter Lead e.g. Operations Manager' 
              value={formState.lead as string}
              onChange={(value:any) => {handleFormChange('lead', value)}}
              containerStyle={{width: '100%'}}
            />
            {
              !isValidLead && (
                <p style={{color: 'red', fontSize: 13, marginBottom: 0, marginTop: 5}}>Please enter a valid lead</p>
              )
            }
          </div>
        </div>
        <div className='d-flex justify-content-between gap-6 mb-5 mt-6'>
          <div style={{width: '100%'}}>
            <p style={{fontSize: 14, fontWeight: 600, color: '#666666', marginBottom: 5}}>TARGET REDUCTION</p>
            <div style={{width: '100%'}} className='py-2 d-flex align-items-center justify-content-between gap-4'>
              <div style={{width: '72%', position: 'relative', padding: 0}}>
                <input
                  type="range"
                  min={0}
                  step={1}
                  max={100}
                  value={emissionPercentage}
                  onChange={(e) => {
                    setEmissionPercentage(parseInt(e.target.value, 10))
                    handleFormChange('targetReduction', (parseInt(e.target.value, 10) * totalScopeEmission / 100))
                  }}
                  style={{
                    background: `linear-gradient(to right, #9268CC 0%, #463578 ${emissionPercentage}%, #ccc ${emissionPercentage}%, #ccc 100%)`,
                  }}
                  className="reduction-initiative-percentage-range"
                  disabled={!formState.emissionsType || totalScopeEmission === 0}
                />
                {/* Moved tooltip to the left acc to range thumb, did some maths to get to the exact position */}
                <div className="reduction-initiative-percentage-range-tooltip" style={{ left: `${emissionPercentage + 4*(50-emissionPercentage)/50}%`, opacity: !formState.emissionsType || totalScopeEmission === 0 ? 0.5 : 1 }}>
                  {emissionPercentage}%
                </div>
              </div>
              <div style={{width: '20%'}}>
                <p className='m-0 fs-4 bold'>
                  {((emissionPercentage || 0) * totalScopeEmission / 100).toFixed(2)}
                  <span style={{fontSize: 12, fontWeight: 600, color: '#666666', marginLeft: '5px'}}>tCO2e</span>
                </p>
              </div>
            </div>
            {
              !isValidTargetReduction && (
                <p style={{color: 'red', fontSize: 13, marginBottom: 0, marginTop: 35}}>Please enter a valid target reduction</p>
              )
            }
          </div>
          <div style={{width: '100%'}}>
            <InputNumber
              label='TARGET YEAR' 
              placeholder='Enter Target Year e.g. 2050' 
              value={formState.targetYear as number}
              onChange={(value:any) => {handleFormChange('targetYear', value)}}
              containerStyle={{width: '100%'}}
            />
            {
              !isValidTargetYear && (
                <p style={{color: 'red', fontSize: 13, marginBottom: 0, marginTop: 5}}>Please enter a valid target year</p>
              )
            }
          </div>
        </div>
        <div className='d-flex flex-column align-items-center justify-content-center mb-2 mt-12'>
          <button 
            type='button' 
            className='custom-primary-delete-button mb-0 mx-0' 
            style={{padding: '8px 20px', fontWeight: 600}}
            onClick={handleSubmit}
            >
            SUBMIT
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}