import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IconFillArrowLeftCircle } from '../../../../../_metronic/assets/icons';
import { IconFillArrowRightCircle } from '../../../../../_metronic/assets/icons';
import { RootState } from '../../../../store/store';
import { DUMMY_ORG_ID } from '../../../../utils/dummyData';

const capatalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);


const ForestTypesCarousel = (props: any) => {
  const {data} = props;

  const {profile} = useSelector((state: RootState) => state.profile);

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const items = profile?.organization?.id !== DUMMY_ORG_ID ? data?.map((item: any) => ({
    name: capatalizeFirstLetter(item?.speciesName),
    count: item?.countPerAcre,
    src: item?.imageUrl
  })) : [
    { name: 'Jamun', count: 40, src: "https://mynzo-staging.s3.ap-south-1.amazonaws.com/forest_masking_proj/saplings_icons/Jamun.png" },
    { name: 'Neem', count: 40, src: "https://mynzo-staging.s3.ap-south-1.amazonaws.com/forest_masking_proj/saplings_icons/Neem.png" },
    { name: 'Mango', count: 50, src: "https://mynzo-staging.s3.ap-south-1.amazonaws.com/forest_masking_proj/saplings_icons/mango.png" },
    { name: 'Guava', count: 40, src: "https://mynzo-staging.s3.ap-south-1.amazonaws.com/forest_masking_proj/saplings_icons/guava.png" },
    { name: 'Palash', count: 50, src: "https://mynzo-staging.s3.ap-south-1.amazonaws.com/forest_masking_proj/saplings_icons/palash.png" },
    { name: 'Coconut', count: 50, src: "https://mynzo-staging.s3.ap-south-1.amazonaws.com/forest_masking_proj/saplings_icons/coconut.png" },
    { name: 'Gulmohar', count: 40, src: "https://mynzo-staging.s3.ap-south-1.amazonaws.com/forest_masking_proj/saplings_icons/gulmohar.png" },
  ];

  return (
    <Carousel
      activeIndex={index}
      indicators={false}
      onSelect={handleSelect}
      className='bordered-grey p-4 mt-4'
      style={{height: '200px', width: '98%', backgroundColor: '#F5F5F5', backgroundImage: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)'}}
      prevIcon={<IconFillArrowLeftCircle height={32} width={32} />}
      nextIcon={<IconFillArrowRightCircle height={32} width={32} />}
      interval={6000}
    >
      {Array.from({ length: Math.ceil((items?.length || 0) / 4) }).map((_, slideIndex) => {
        const slideStart = slideIndex * 4;
        const slideEnd = slideStart + 4;
        const slideId = `slide-${slideStart}-${slideEnd}`;
        
        return (
          <Carousel.Item key={slideId}>
            <div className="d-flex justify-content-center gap-2">
              {items.slice(slideStart, slideEnd).map((item: any) => (
                <div key={item.name} style={{width: '19%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  <img
                    className="d-block"
                    style={{
                      height: '80px',
                      width: '80px',
                      borderRadius: '40px',
                      marginTop: '15px'
                    }}
                    src={item.src}
                    alt={item.name}
                  />
                  <p className='m-0 mt-6' style={{fontSize: '14px', fontWeight: '600'}}>{item.name}</p>
                  {
                    profile?.organization?.id !== DUMMY_ORG_ID && (
                      <p className='m-0 mt-1' style={{fontSize: '12px'}}><span style={{fontWeight: '600'}}>{item.count}</span> per acre</p>
                    )
                  }
                </div>
              ))}
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default ForestTypesCarousel;