import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type SectionStatus = "completed" | "in-progress" | "not-started";

export type FORM_FIELDS = 
  // Office Electricity Consumption
  "country" |
  "state" |
  "city" |
  "totalOfficeSquareFootage" |
  "numberOfEmployeesInOffice" |
  "averageWFHDaysPerWeek" |
  "isLEEDGoldCertified" |
  "isLEEDPlatinumCertified" |
  "isIGBCCertified" |
  "isRenewableEnergySourced" |

  // Employee Commute
  "numberOfCompanyVehicles" |
  "averageCommuteDistancePerDay" |
  "numberOfEmployeesUsingPrivateVehicles" |
  "averageCommuteDistancePerEmployee" |

  // Light Travelers
  "numberOfLightTravelers" |
  "lightTravelersLocalTrips" |
  "lightTravelersLocalClass" |
  "lightTravelersMediumTrips" |
  "lightTravelersMediumClass" |
  "lightTravelersLongTrips" |
  "lightTravelersLongClass" |

  // Heavy Travelers  
  "numberOfHeavyTravelers" |
  "heavyTravelersLocalTrips" |
  "heavyTravelersLocalClass" |
  "heavyTravelersMediumTrips" |
  "heavyTravelersMediumClass" |
  "heavyTravelersLongTrips" |
  "heavyTravelersLongClass"

// Add new type definitions for location fields
type LocationField = {
  id: number | null
  name: string
}

export type OfficeDetail = {
  country: LocationField,
  state: LocationField,
  city: LocationField,
  stateList: any[],
  cityList: any[],
  totalOfficeSquareFootage: number | undefined,
  numberOfEmployeesInOffice: number | undefined,
  averageWFHDaysPerWeek: number | undefined,
  isLEEDGoldCertified: string,
  isLEEDPlatinumCertified: string,
  isIGBCCertified: string,
  isRenewableEnergySourced: string,
}

export type FormType = {
  // Section Statuses
  officeElectricityStatus: SectionStatus,
  employeeCommuteStatus: SectionStatus,
  lightTravelersStatus: SectionStatus,
  heavyTravelersStatus: SectionStatus,

  // Office Electricity Consumption
  officeDetails: OfficeDetail[],
  
  // Employee Commute
  numberOfCompanyVehicles: number | undefined,
  averageCommuteDistancePerDay: number | undefined,
  numberOfEmployeesUsingPrivateVehicles: number | undefined,
  averageCommuteDistancePerEmployee: number | undefined,

  // Light Travelers
  numberOfLightTravelers: number | undefined,
  lightTravelersLocalTrips: number | undefined,
  lightTravelersLocalClass: string,
  lightTravelersMediumTrips: number | undefined,
  lightTravelersMediumClass: string,
  lightTravelersLongTrips: number | undefined,
  lightTravelersLongClass: string,

  // Heavy Travelers
  numberOfHeavyTravelers: number | undefined,
  heavyTravelersLocalTrips: number | undefined,
  heavyTravelersLocalClass: string,
  heavyTravelersMediumTrips: number | undefined,
  heavyTravelersMediumClass: string,
  heavyTravelersLongTrips: number | undefined,
  heavyTravelersLongClass: string
}

export type SetFormActionType = {
  payload: {
    field: FORM_FIELDS,
    data: any,
  },
  type: string
}

export type SetSectionStatusActionType = {
  payload: {
    section: "officeElectricityStatus" | "employeeCommuteStatus" | "lightTravelersStatus" | "heavyTravelersStatus",
    status: SectionStatus
  },
  type: string
}

export type SetOfficeDetailActionType = {
  payload: {
    index: number,
    field: keyof OfficeDetail,
    data: any,
  },
  type: string
}

const initialState: FormType = {
  // Section Statuses
  officeElectricityStatus: "not-started",
  employeeCommuteStatus: "not-started", 
  lightTravelersStatus: "not-started",
  heavyTravelersStatus: "not-started",

  // Office Electricity Consumption
  officeDetails: [{
    country: { id: null, name: '' },
    state: { id: null, name: '' },
    city: { id: null, name: '' },
    stateList: [],
    cityList: [],
    totalOfficeSquareFootage: undefined,
    numberOfEmployeesInOffice: undefined,
    averageWFHDaysPerWeek: undefined,
    isLEEDGoldCertified: '',
    isLEEDPlatinumCertified: '',
    isIGBCCertified: '',
    isRenewableEnergySourced: '',
  }],
  
  // Employee Commute
  numberOfCompanyVehicles: undefined,
  averageCommuteDistancePerDay: undefined,
  numberOfEmployeesUsingPrivateVehicles: undefined,
  averageCommuteDistancePerEmployee: undefined,

  // Light Travelers
  numberOfLightTravelers: undefined,
  lightTravelersLocalTrips: undefined,
  lightTravelersLocalClass: 'Economy',
  lightTravelersMediumTrips: undefined,
  lightTravelersMediumClass: 'Economy',
  lightTravelersLongTrips: undefined,
  lightTravelersLongClass: 'Economy',

  // Heavy Travelers
  numberOfHeavyTravelers: undefined,
  heavyTravelersLocalTrips: undefined,
  heavyTravelersLocalClass: 'Economy',
  heavyTravelersMediumTrips: undefined,
  heavyTravelersMediumClass: 'Economy',
  heavyTravelersLongTrips: undefined,
  heavyTravelersLongClass: 'Economy'
}

export const scopeSurveyFormSlice = createSlice({
  name: 'scopeSurveyForm',
  initialState,
  reducers: {
    setScopeSurveyFormField: (state: any, action: SetFormActionType) => {
      state[action.payload.field] = action.payload.data;
    },
    setSectionStatus: (state: any, action: SetSectionStatusActionType) => {
      state[action.payload.section] = action.payload.status;
    },
    setOfficeDetail: (state: any, action: SetOfficeDetailActionType) => {
      const { index, field, data } = action.payload;
      state.officeDetails[index][field] = data;
    },
    addNewOffice: (state) => {
      state.officeDetails.push({
        country: { id: null, name: '' },
        state: { id: null, name: '' },
        city: { id: null, name: '' },
        stateList: [],
        cityList: [],
        totalOfficeSquareFootage: undefined,
        numberOfEmployeesInOffice: undefined,
        averageWFHDaysPerWeek: undefined,
        isLEEDGoldCertified: '',
        isLEEDPlatinumCertified: '',
        isIGBCCertified: '',
        isRenewableEnergySourced: '',
      });
    },
    removeOffice: (state, action: PayloadAction<number>) => {
      // Don't remove if it's the last office
      if (state.officeDetails.length > 1) {
        state.officeDetails = state.officeDetails.filter((_, index) => index !== action.payload);
      }
    },
    setAllSurveyFormFieldsBySurveyInputData: (state, action: any) => {
      // FILL OFFICE DETAILS
      state.officeDetails = action.payload?.officeElectricityConsumptionDetails?.map((office: any) => ({
        // TODO: get state, city, stateList, cityList
        country: office?.country || { id: null, name: '' },
        state: office?.state || { id: null, name: '' },
        city: office?.city || { id: null, name: '' },
        stateList: [],
        cityList: [],
        totalOfficeSquareFootage: office?.areaSqFt || undefined,
        numberOfEmployeesInOffice: office?.noOfEmployees || undefined,
        averageWFHDaysPerWeek: office?.avgWfhDaysPerWeekPerEmployee || undefined,
        isLEEDGoldCertified: office?.isLeedGoldCertified ? 'yes' : 'no',
        isLEEDPlatinumCertified: office?.isLeedPlatinumCertified ? 'yes' : 'no',
        isIGBCCertified: office?.isIgbcCertified ? 'yes' : 'no',
        isRenewableEnergySourced: office?.isRenewableEnergySourced ? 'yes' : 'no',
      })) || [{
        country: { id: null, name: '' },
        state: { id: null, name: '' },
        city: { id: null, name: '' },
        stateList: [],
        cityList: [],
        totalOfficeSquareFootage: undefined,
        numberOfEmployeesInOffice: undefined,
        averageWFHDaysPerWeek: undefined,
        isLEEDGoldCertified: '',
        isLEEDPlatinumCertified: '',
        isIGBCCertified: '',
        isRenewableEnergySourced: '',
      }]

      // FILL EMPLOYEE COMMUTE
      state.numberOfCompanyVehicles = action.payload?.employeeCommuteDetails?.noOfCompanyOwnedVehicles || undefined
      state.averageCommuteDistancePerDay = action.payload?.employeeCommuteDetails?.avgKmPerDayPerVehicle || undefined
      state.numberOfEmployeesUsingPrivateVehicles = action.payload?.employeeCommuteDetails?.noOfEmployeeUsingPvtVehicles || undefined
      state.averageCommuteDistancePerEmployee = action.payload?.employeeCommuteDetails?.avgKmPerEmployeePerDay || undefined

      // FILL LIGHT TRAVELERS
      state.numberOfLightTravelers = action.payload?.lightTravelerDetails?.noOfEmployeeInLightTravels || undefined
      state.lightTravelersLocalTrips = action.payload?.lightTravelerDetails?.localAvgRoundTripPerPersonPerQuarter || undefined
      state.lightTravelersLocalClass = action.payload?.lightTravelerDetails?.localTravelClass || 'Economy'
      state.lightTravelersMediumTrips = action.payload?.lightTravelerDetails?.mediumAvgRoundTripPerPersonPerQuarter || undefined
      state.lightTravelersMediumClass = action.payload?.lightTravelerDetails?.mediumTravelClass || 'Economy'
      state.lightTravelersLongTrips = action.payload?.lightTravelerDetails?.longAvgRoundTripPerPersonPerQuarter || undefined
      state.lightTravelersLongClass = action.payload?.lightTravelerDetails?.longTravelClass || 'Economy'

      // FILL HEAVY TRAVELERS
      state.numberOfHeavyTravelers = action.payload?.heavyTravelerDetails?.noOfEmployeeInHeavyTravels || undefined
      state.heavyTravelersLocalTrips = action.payload?.heavyTravelerDetails?.localAvgRoundTripPerPersonPerQuarter || undefined
      state.heavyTravelersLocalClass = action.payload?.heavyTravelerDetails?.localTravelClass || 'Economy'
      state.heavyTravelersMediumTrips = action.payload?.heavyTravelerDetails?.mediumAvgRoundTripPerPersonPerQuarter || undefined
      state.heavyTravelersMediumClass = action.payload?.heavyTravelerDetails?.mediumTravelClass || 'Economy'
      state.heavyTravelersLongTrips = action.payload?.heavyTravelerDetails?.longAvgRoundTripPerPersonPerQuarter || undefined
      state.heavyTravelersLongClass = action.payload?.heavyTravelerDetails?.longTravelClass || 'Economy'

    },
    clearAllScopeSurveyFormFields: (state) => {
      // Section Statuses
      state.officeElectricityStatus = "not-started";
      state.employeeCommuteStatus = "not-started";
      state.lightTravelersStatus = "not-started";
      state.heavyTravelersStatus = "not-started";

      // Office Electricity Consumption
      state.officeDetails = [{
        country: { id: null, name: '' },
        state: { id: null, name: '' },
        city: { id: null, name: '' },
        stateList: [],
        cityList: [],
        totalOfficeSquareFootage: undefined,
        numberOfEmployeesInOffice: undefined,
        averageWFHDaysPerWeek: undefined,
        isLEEDGoldCertified: '',
        isLEEDPlatinumCertified: '',
        isIGBCCertified: '',
        isRenewableEnergySourced: '',
      }];

      // Employee Commute
      state.numberOfCompanyVehicles = undefined;
      state.averageCommuteDistancePerDay = undefined;
      state.numberOfEmployeesUsingPrivateVehicles = undefined;
      state.averageCommuteDistancePerEmployee = undefined;

      // Light Travelers
      state.numberOfLightTravelers = undefined;
      state.lightTravelersLocalTrips = undefined;
      state.lightTravelersLocalClass = '';
      state.lightTravelersMediumTrips = undefined;
      state.lightTravelersMediumClass = '';
      state.lightTravelersLongTrips = undefined;
      state.lightTravelersLongClass = '';

      // Heavy Travelers
      state.numberOfHeavyTravelers = undefined;
      state.heavyTravelersLocalTrips = undefined;
      state.heavyTravelersLocalClass = '';
      state.heavyTravelersMediumTrips = undefined;
      state.heavyTravelersMediumClass = '';
      state.heavyTravelersLongTrips = undefined;
      state.heavyTravelersLongClass = '';
    },
    updateSurveyBasedDataOfficeElectricityConsumptionDetails: (state, action) => {
      if (action.payload?.length > 0) {
        state.officeDetails = action.payload
      }
    }
  }
});

export const {
  setScopeSurveyFormField,
  setSectionStatus,
  setAllSurveyFormFieldsBySurveyInputData,
  clearAllScopeSurveyFormFields,
  setOfficeDetail,
  addNewOffice,
  removeOffice,
  updateSurveyBasedDataOfficeElectricityConsumptionDetails
} = scopeSurveyFormSlice.actions;

export default scopeSurveyFormSlice.reducer;
