export const transformGrowerForestImagesData = (growerForestImagesData: any) => {
  const images = growerForestImagesData.data

  let isEmpty = true;

  const parsedImages = images.reduce((acc: any, url: string) => {
    // Extract data from the URL
    const urlParts = url.split('_')
    const lastIndex = urlParts.length - 1
    const isVisible = urlParts[lastIndex].split('-')[0] === '1'
    const radius = parseFloat(urlParts[lastIndex - 1])
    const height = parseFloat(urlParts[lastIndex - 2]) 
    const width = parseFloat(urlParts[lastIndex - 3])
    const species = urlParts[lastIndex - 4]

    // Group by species
    if (!acc[species]) {
      acc[species] = []
    }

    const imageUrl = `https://mynzo.s3.ap-south-1.amazonaws.com/${url}`

    if (isVisible) {
      isEmpty = false;
      acc[species].push({
        species,
        url: imageUrl,
        width,
        height, 
        radius,
        isVisible
      })
    }

    return acc
  }, {})

  return {parsedImages, isEmpty};
} 