import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Carousel } from 'react-bootstrap';
import { IconFillArrowRightCircle, IconFillArrowLeftCircle } from '../../../../../_metronic/assets/icons';
import { RootState } from '../../../../store/store';
import { DUMMY_ORG_ID } from '../../../../utils/dummyData';


const ForestBoundaryCarousel = (props: any) => {
  const {data} = props;
  const [index, setIndex] = useState(0);

  const {profile} = useSelector((state: RootState) => state.profile);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  if (data?.length > 0) {
    return (
      <Carousel 
        activeIndex={index} 
        onSelect={handleSelect} 
        interval={3000} 
        style={{borderRadius: 10, height: '100%', width: '100%', position: 'relative', backgroundImage: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)'}}
        prevIcon={<IconFillArrowLeftCircle height={32} width={32} />}
        nextIcon={<IconFillArrowRightCircle height={32} width={32} />}
      >
        {data?.map((item: any, ind: number) => (
          <Carousel.Item key={`${item?.date}`} style={{height: '100%'}}>
            <img
              className="d-block"
              src={item?.imageUrl}
              alt={`Forest Boundary ${ind + 1}`}
              style={{objectFit: 'fill', height: '110%', alignSelf: 'center'}}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }

  if (profile?.organization?.id !== DUMMY_ORG_ID) {
    return (
      <Carousel 
      activeIndex={index} 
      onSelect={handleSelect} 
      interval={3000} 
      style={{borderRadius: 10, height: '100%', width: '100%', position: 'relative', backgroundImage: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)'}}
      prevIcon={<IconFillArrowLeftCircle height={32} width={32} />}
      nextIcon={<IconFillArrowRightCircle height={32} width={32} />}
    >
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo.s3.ap-south-1.amazonaws.com/forest_lulc/year_1.png"
          alt="First slide"
          style={{objectFit: 'fill', height: '110%', alignSelf: 'center'}}
        />
      </Carousel.Item>
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo.s3.ap-south-1.amazonaws.com/forest_lulc/year_2.png"
          alt="Second slide"
          style={{objectFit: 'contain', height: '110%', alignSelf: 'center'}}
        />
      </Carousel.Item>
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo.s3.ap-south-1.amazonaws.com/forest_lulc/year_3.png"
          alt="Third slide"
          style={{objectFit: 'contain', height: '110%', alignSelf: 'center'}}
        />
      </Carousel.Item>
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo.s3.ap-south-1.amazonaws.com/forest_lulc/year_4.png"
          alt="First slide"
          style={{objectFit: 'fill', height: '110%', alignSelf: 'center'}}
        />
      </Carousel.Item>
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo.s3.ap-south-1.amazonaws.com/forest_lulc/year_5.png"
          alt="First slide"
          style={{objectFit: 'fill', height: '110%', alignSelf: 'center'}}
        />
      </Carousel.Item>
    </Carousel>
    )
  }

  return (
    <Carousel 
      activeIndex={index} 
      onSelect={handleSelect} 
      interval={3000} 
      style={{borderRadius: 10, height: '100%', width: '100%', position: 'relative', backgroundImage: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)'}}
      prevIcon={<IconFillArrowLeftCircle height={32} width={32} />}
      nextIcon={<IconFillArrowRightCircle height={32} width={32} />}
    > 
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo-staging.s3.ap-south-1.amazonaws.com/forest_masking_proj/mask_and_crowns/lnt_site/Identified_sapling_and_tree_masks_1.PNG"
          alt="First slide"
          style={{objectFit: 'fill', height: '110%', alignSelf: 'center'}}
        />
      </Carousel.Item>
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo-staging.s3.ap-south-1.amazonaws.com/forest_masking_proj/mask_and_crowns/lnt_site/Identified_sapling_and_tree_crowns_1.PNG"
          alt="Second slide"
          style={{objectFit: 'contain', height: '110%', alignSelf: 'center'}}
        />
      </Carousel.Item>
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo-staging.s3.ap-south-1.amazonaws.com/forest_masking_proj/mask_and_crowns/lnt_site/Identified_sapling_and_tree_masks_2.PNG"
          alt="Third slide"
          style={{objectFit: 'contain', height: '110%', alignSelf: 'center'}}
        />
      </Carousel.Item>
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo-staging.s3.ap-south-1.amazonaws.com/forest_masking_proj/mask_and_crowns/lnt_site/Identified_sapling_and_tree_crowns_2.PNG"
          alt="Fourth slide"
          style={{objectFit: 'fill', height: '110%', alignSelf: 'center'}}
        />
      </Carousel.Item>
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo-staging.s3.ap-south-1.amazonaws.com/forest_masking_proj/mask_and_crowns/lnt_site/Identified_sapling_and_tree_masks_3.PNG"
          alt="Fifth slide"
          style={{objectFit: 'fill', height: '110%', alignSelf: 'center'}}
        />
      </Carousel.Item>
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo-staging.s3.ap-south-1.amazonaws.com/forest_masking_proj/mask_and_crowns/lnt_site/Identified_sapling_and_tree_crowns_3.PNG"
          alt="Sixth slide"
          style={{objectFit: 'fill', height: '110%', alignSelf: 'center'}}
        />
      </Carousel.Item>
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo-staging.s3.ap-south-1.amazonaws.com/forest_masking_proj/mask_and_crowns/lnt_site/Identified_sapling_and_tree_masks_4.PNG"
          alt="Seventh slide"
          style={{objectFit: 'fill', height: '110%', alignSelf: 'center'}}
        />
      </Carousel.Item>
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo-staging.s3.ap-south-1.amazonaws.com/forest_masking_proj/mask_and_crowns/lnt_site/Identified_sapling_and_tree_crowns_4.PNG"
          alt="Eighth slide"
          style={{objectFit: 'fill', height: '110%', alignSelf: 'center'}}
        />
      </Carousel.Item>
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo-staging.s3.ap-south-1.amazonaws.com/forest_masking_proj/mask_and_crowns/lnt_site/all_masks.PNG"
          alt="Ninth slide"
          style={{objectFit: 'fill', height: '110%', alignSelf: 'center'}}
        />
      </Carousel.Item>
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo-staging.s3.ap-south-1.amazonaws.com/forest_masking_proj/mask_and_crowns/lnt_site/lnt_project_site.png"
          alt="Tenth slide"
          style={{objectFit: 'fill', height: '110%', alignSelf: 'center'}}
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default ForestBoundaryCarousel;
