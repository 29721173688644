/* eslint-disable global-require */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import moment from 'moment';

import { useLazyGetGrowerForestImagesQuery } from '../configuration/store/api';
import { transformGrowerForestImagesData } from './helper';
import { RoutesPath } from '../../routing/RoutesPath';

import './styles.css';
import { IconArrowRightCircle } from '../../../_metronic/assets/icons';

type Props = {
}

const SpeciesCard = ({data, species}: any) => {
  const navigate = useNavigate();
  if (data.length === 0) return null;
  return (
    <button 
      type='button'
      className='remove-btn-styles d-flex align-items-center justify-content-between flex-row gap-4 bordered-grey p-4 mb-4'
      style={{width: '60%'}}
      onClick={() => {
        navigate(RoutesPath.FOREST.GROWER_FOREST_SPECIES_UPDATES, {
          state: {
            data,
            species
          }
        })
      }}
      >
      <div className='d-flex flex-row gap-2 align-items-center'>
        <img 
          src={data[0].url} 
          alt={data[0].url} 
          key={`${data[0].url}`} 
          style={{width: '100px', height: '100px', borderRadius: '10px'}} 
          />
        <div className='d-flex flex-column flex-start justify-content-between h-100'>
          <h3 className='text-xl font-bold mb-4'>{species}</h3>
          <p className='mb-0'>{data.length} update{data.length > 1 ? 's' : ''}</p>
        </div>
      </div>
      <div className='d-flex flex-row gap-2 align-items-center'>
        <IconArrowRightCircle height={24} width={24} />
      </div>
    </button>
  )
}

const GrowerForestUpdateImagesPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const [getGrowerForestImages, {
    data: growerForestImagesData, 
    isLoading: isGetGrowerForestImagesLoading, 
    isSuccess: isGetGrowerForestImagesSuccess
  }] = useLazyGetGrowerForestImagesQuery();

  const [growerForestImages, setGrowerForestImages] = useState<any>({});
  const [isDataEmpty, setIsDataEmpty] = useState<boolean>(true);

  useEffect(() => {
    getGrowerForestImages({});
  }, []);

  useEffect(() => {
    if(isGetGrowerForestImagesSuccess) {
      const {parsedImages, isEmpty} = transformGrowerForestImagesData(growerForestImagesData);
      setGrowerForestImages(parsedImages);
      setIsDataEmpty(isEmpty);
    }
  }, [isGetGrowerForestImagesSuccess]);

  return (
    <div className='blank-page-view py-8 pb-12 px-12'>

      <div className='back-header-btn'>
        <button 
          type='button' 
          className='p-2 back-left-arrow-btn-wrap'
          onClick={()=>{navigate(-1)}}
        >
          <div className='back-left-arrow-btn' /> 
        </button>
      </div>

      <h2 className='text-2xl font-bold mt-4 mb-10'>PLANTATION UPDATES</h2>

      {
        isDataEmpty ? (
          <div className='flex justify-center items-center h-screen'>
            <h3 className='text-xl font-bold text-center mt-12'>No updates found</h3>
          </div>
        ) : (
          <h3 className='text-xl font-bold'>
            {moment().format('DD-MM-YYYY')}
          </h3>
        )
      }

      {
        Object.keys(growerForestImages).map((species: any) => (
          <div key={species}>
            <SpeciesCard data={growerForestImages[species]} species={species} />
          </div>
        ))
      }
      
    </div>
  )
}

export default GrowerForestUpdateImagesPage
/* eslint-disable global-require */
/* eslint-disable react/no-unstable-nested-components */