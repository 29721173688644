/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getStateList, getCityList } from "./api";
import { updateSurveyBasedDataOfficeElectricityConsumptionDetails } from "../../../store/slices/forms/scopeSurveyForm";

export const useSetOfficeDetailsWithStatesAndCityLists = () => {
  const dispatch = useDispatch()
  const [updatedOfficeDetails, setUpdatedOfficeDetails] = useState<any[]>([])
  const setOfficeElectricityConsumptionDetails = async (officeElectricityConsumptionDetails: any) => {
    if (updatedOfficeDetails === officeElectricityConsumptionDetails) {
      return;
    }
    const updatedOfficeElectricityConsumptionDetails: any[] = [];
    for (const office of officeElectricityConsumptionDetails) {
      const copyOffice = {...office}
      let stateList = []
      let cityList = []
      let currentState = null
      if (office?.country?.id) {
        const stateResponse = await getStateList(office.country.id)
        stateList = stateResponse?.data
        // SET STATE LIST
        copyOffice.stateList = stateList || []
      }
      if (office?.state?.name) {
        currentState = stateList.find((state: any) => state.mg_name === office.state.name) || null
        // SET STATE
        copyOffice.state = { id: currentState?.mg_id || null, name: currentState?.mg_name || '' }
      }
      if (currentState?.mg_id) {
        const cityResponse = await getCityList(currentState.mg_id)
        cityList = cityResponse?.data
        // SET CITY LIST
        copyOffice.cityList = cityList || []
        if (office?.city?.name) {
          const currentCity = cityList.find((city: any) => city.mg_name === office.city.name) || null
          // SET CITY
          copyOffice.city = { id: currentCity?.mg_id || null, name: currentCity?.mg_name || '' }
        }
      } else {
        copyOffice.cityList = []
        copyOffice.city = { id: null, name: '' }
      }
      updatedOfficeElectricityConsumptionDetails.push(copyOffice)
    }
    setUpdatedOfficeDetails(updatedOfficeElectricityConsumptionDetails)
    if (updatedOfficeElectricityConsumptionDetails?.length > 0) {
      dispatch(updateSurveyBasedDataOfficeElectricityConsumptionDetails(updatedOfficeElectricityConsumptionDetails || []));
    }
  }
  return [setOfficeElectricityConsumptionDetails];
}
