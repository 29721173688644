/* eslint-disable no-unneeded-ternary */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { AccordionDivProps } from "../types";
import { RenderStatusPill } from "./render-status-pill";
import { InputNumber } from "../../../../ui/components/input-text";
import { setScopeSurveyFormField, setSectionStatus, FORM_FIELDS, SectionStatus } from "../../../../store/slices/forms/scopeSurveyForm";
import { RootState } from "../../../../store/store";
import { usePostManualYearCumulativeSurveyMutation } from "../../store/api";
import { updateNotification } from "../../../../store/slices/notifier";
import { NotificationType } from "../../../../../_metronic/partials/components/types";
import { ManualYearCumulativeSurveyEmployeeCommuteDetailsResponseType, ManualYearCumulativeSurveyRequestOptionalType, ManualYearCumulativeSurveyRequestType } from "../../store/type";
import { useGetProfileDetailsQuery } from "../../../../routing/store/api";
import { updateProfile } from "../../../../store/slices/profile";
import { validateEmployeeCommute } from "../validation";

const AccordionDivTwo = (props: AccordionDivProps) => {
  const {data} = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const formState = useSelector((state: RootState) => state.scopeSurveyForm);
  const {data: profileDetails, refetch: refetchUserProfileData} = useGetProfileDetailsQuery();
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const surveyInputData = useSelector((state: RootState) => state.profile.surveyInputData);
  const [postSurvey, {isSuccess: isSuccessPostSurvey, isError: isErrorPostSurvey}] = usePostManualYearCumulativeSurveyMutation();

  useEffect(() => {
    if (profileDetails) {
      dispatch(updateProfile(profileDetails))
    }
  }, [profileDetails])

  useEffect(() => {
    if (isSuccessPostSurvey) {
      dispatch(
        updateNotification({
          message: 'Employee office commute submitted successfully',
          type: NotificationType.SUCCESS
        })
      );
    }
  }, [isSuccessPostSurvey]);

  useEffect(() => {
    if (isErrorPostSurvey) {
      dispatch(
        updateNotification({
          message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
          type: NotificationType.ERROR,
        })
      );
    }
  }, [isErrorPostSurvey]);

  const handleSubmit = async () => {
    // Reset validation states
    setIsValid(true);
    setErrorMessage("");

    try {
      // Transform form data to API format
      const payload: ManualYearCumulativeSurveyRequestOptionalType = {
        ...(
          surveyInputData?.officeElectricityConsumptionDetails ? {
            office_electricity_consumption_details: surveyInputData?.officeElectricityConsumptionDetails?.map((office: any) => ({
              geo_id: office?.geoId || 0,
              country: office?.country,
              state: office?.state,
              city: office?.city,
              area_sq_ft: office?.areaSqFt || 0,
              no_of_employees: office?.noOfEmployees || 0,
              avg_wfh_days_per_week_per_employee: office?.avgWfhDaysPerWeekPerEmployee || 0,
              is_igbc_certified: office?.isIgbcCertified ? true : false,
              is_renewable_energy_sourced: office?.isRenewableEnergySourced ? true : false
            })) || []
          } : {}
        ),
        // SETTING EMPLOYEE COMMUTE DETAILS
        employee_commute_details: {
          no_of_company_owned_vehicles: formState.numberOfCompanyVehicles || 0,
          avg_km_per_day_per_vehicle: formState.averageCommuteDistancePerDay || 0,
          no_of_employee_using_pvt_vehicles: formState.numberOfEmployeesUsingPrivateVehicles || 0,
          avg_km_per_employee_per_day: formState.averageCommuteDistancePerEmployee || 0
        },
        ...(
          surveyInputData?.lightTravelerDetails ? {
            light_traveler_details: {
              no_of_employee_in_light_travels: surveyInputData?.lightTravelerDetails?.noOfEmployeeInLightTravels || 0,
              local_avg_round_trip_per_person_per_quarter: surveyInputData?.lightTravelerDetails?.localAvgRoundTripPerPersonPerQuarter || 0,
              local_travel_class: String(surveyInputData?.lightTravelerDetails?.localTravelClass || 'Economy'),
              medium_avg_round_trip_per_person_per_quarter: surveyInputData?.lightTravelerDetails?.mediumAvgRoundTripPerPersonPerQuarter || 0,
              medium_travel_class: String(surveyInputData?.lightTravelerDetails?.mediumTravelClass || 'Economy'),
              long_avg_round_trip_per_person_per_quarter: surveyInputData?.lightTravelerDetails?.longAvgRoundTripPerPersonPerQuarter || 0,
              long_travel_class: String(surveyInputData?.lightTravelerDetails?.longTravelClass || 'Economy')
            }
          } : {}
        ),
        ...(
          surveyInputData?.heavyTravelerDetails ? {
            heavy_traveler_details: {
              no_of_employee_in_heavy_travels: surveyInputData?.heavyTravelerDetails?.noOfEmployeeInHeavyTravels || 0,
              local_avg_round_trip_per_person_per_quarter: surveyInputData?.heavyTravelerDetails?.localAvgRoundTripPerPersonPerQuarter || 0,
              local_travel_class: String(surveyInputData?.heavyTravelerDetails?.localTravelClass || 'Economy'),
              medium_avg_round_trip_per_person_per_quarter: surveyInputData?.heavyTravelerDetails?.mediumAvgRoundTripPerPersonPerQuarter || 0,
              medium_travel_class: String(surveyInputData?.heavyTravelerDetails?.mediumTravelClass || 'Economy'),
              long_avg_round_trip_per_person_per_quarter: surveyInputData?.heavyTravelerDetails?.longAvgRoundTripPerPersonPerQuarter || 0,
              long_travel_class: String(surveyInputData?.heavyTravelerDetails?.longTravelClass || 'Economy')
            }
          } : {}
        )
      };

      // Validate required fields
      const isValidForm = validateEmployeeCommute(payload.employee_commute_details)

      if (!isValidForm.isValid) {
        setIsValid(false)
        setErrorMessage(isValidForm.errorMessage)
        return
      }

      // Submit the data
      const response = await postSurvey(payload).unwrap();
      await refetchUserProfileData();
      
    } catch (error) {
      console.error('Error submitting survey:', error);
      setIsValid(false);
      setErrorMessage("Failed to submit survey. Please try again.");
    }
  };

  const handleFieldChange = (field: FORM_FIELDS, value: any) => {
    dispatch(setScopeSurveyFormField({field, data: value}));

    // Create updated state with new value
    const updatedState = {
      ...formState,
      [field]: value
    };

    // Check status after field update using updated state
    const fieldsToCheck = [
      updatedState.numberOfCompanyVehicles,
      updatedState.averageCommuteDistancePerDay,
      updatedState.numberOfEmployeesUsingPrivateVehicles,
      updatedState.averageCommuteDistancePerEmployee
    ];

    const isStarted = fieldsToCheck.some(fieldValue => fieldValue !== undefined);

    const isCompleted = fieldsToCheck.every(fieldValue => fieldValue !== undefined); 

    let status: SectionStatus = "not-started";
    // if (isCompleted) {
    //   status = "completed";
    // } else 
    if (isStarted) {
      status = "in-progress";
    }

    dispatch(setSectionStatus({
      section: "employeeCommuteStatus",
      status
    }));
  };

  return (
    <div className="accordion bordered-grey" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
      <div className="accordion-item" style={{border: 'none'}}>
        <h2 className="accordion-header py-2" id='twoSection'>
          <button 
            className="accordion-button collapsed fs-3 bold bg-white" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target='#panel-twoSection' 
            aria-expanded="false" 
            aria-controls='panel-twoSection'
          >
            <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{data?.title}</p>
          </button>
          <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
            <RenderStatusPill status={data?.status} />
          </div>
        </h2>
        <div id='panel-twoSection' className="accordion-collapse collapse" aria-labelledby='twoSection'>
          <div className="accordion-body">
            <div className='row px-8 py-6'>
              <div className='col-4'>
                <InputNumber
                  label='Number of Company-owned Vehicles'
                  value={formState.numberOfCompanyVehicles}
                  onChange={(v) => handleFieldChange('numberOfCompanyVehicles', v)}
                  placeholder='Enter Number of Vehicles'
                />
              </div>
              <div className='col-4'>
                <InputNumber
                  label='Average Commute Distance Per Day'
                  value={formState.averageCommuteDistancePerDay}
                  onChange={(v) => handleFieldChange('averageCommuteDistancePerDay', v)}
                  placeholder='Enter Average Distance'
                  unit='km'
                />
              </div>
              <div className='col-4'>
                <InputNumber
                  label='Number of Employees Using Private Vehicles'
                  value={formState.numberOfEmployeesUsingPrivateVehicles}
                  onChange={(v) => handleFieldChange('numberOfEmployeesUsingPrivateVehicles', v)}
                  placeholder='Enter Number of Employees'
                />
              </div>
            </div>
            <div className='row px-8 py-6'>
              <div className='col-4'>
                <InputNumber
                  label='Average Commute Distance/Employee/Day'
                  value={formState.averageCommuteDistancePerEmployee}
                  onChange={(v) => handleFieldChange('averageCommuteDistancePerEmployee', v)}
                  placeholder='Enter Average Distance'
                  unit='km'
                />
              </div>
            </div>
            <div className='row px-8 py-6'>
              <div className='col-12 d-flex justify-content-end'>
                {!isValid && (
                  <p className="text-danger mt-2 mb-0 mx-6" style={{fontSize: '14px'}}>
                    {errorMessage}
                  </p>
                )}
                <button
                  type='button'
                  className='remove-button-styles fs-5'
                  style={{
                    borderRadius: 10,
                    color: 'white',
                    backgroundColor: '#0491BD',
                    border: 'none',
                    padding: '8px 30px',
                    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                    fontWeight: 500
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionDivTwo;