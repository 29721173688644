import { styles } from "./styles";

interface DropdownProps {
  label: string;
  options: { label: string; value: string | number; }[];
  onChange: (value: any) => void;
  placeholder: string;
  selectedValue?: string | number | null;
  disabled?: boolean;
  dropdownStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  disablePlaceholder?: boolean;
}

export const Dropdown = (props: DropdownProps) => {
  const {
    options,
    onChange,
    placeholder,
    label,
    selectedValue,
    disabled = false,
    dropdownStyle,
    containerStyle,
    disablePlaceholder = false
  } = props;

  return (
    <div style={containerStyle}>
      {label && <p style={styles.label}>{label}</p>}
      <select
        disabled={disabled}
        className='form-select'
        onChange={(event: any) => {
          const value = !Number.isNaN(Number(event.target.value)) 
            ? Number(event.target.value) 
            : event.target.value;
          onChange(value);
        }}
        style={{...styles.greyBorder, padding: '8px 10px', fontSize: 15, marginTop: 6, ...dropdownStyle}}
        value={selectedValue || ''}
      >
        {!disablePlaceholder && <option value=''>{placeholder}</option>}
        {
          options.map((item: any) => (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          ))
        }
      </select>
    </div>
  )
}