/* eslint-disable no-unneeded-ternary */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { AccordionDivProps } from "../types";
import { RenderStatusPill } from "./render-status-pill";
import { InputNumber } from "../../../../ui/components/input-text";
import { Dropdown } from "../../../../ui/components/dropdown";
import { setSectionStatus, OfficeDetail, SectionStatus, setOfficeDetail, addNewOffice, removeOffice } from "../../../../store/slices/forms/scopeSurveyForm";
import { RootState } from "../../../../store/store";
import { getStateList, getCityList } from "../api";
import { usePostManualYearCumulativeSurveyMutation } from "../../store/api";
import { updateNotification } from "../../../../store/slices/notifier";
import { NotificationType } from "../../../../../_metronic/partials/components/types";
import { ManualYearCumulativeSurveyOfficeDetailsResponseType, ManualYearCumulativeSurveyRequestOptionalType, ManualYearCumulativeSurveyRequestType } from "../../store/type";
import { validateOfficeDetails } from "../validation";
import { useGetProfileDetailsQuery } from "../../../../routing/store/api";
import { updateProfile } from "../../../../store/slices/profile";

const AccordionDivOne = (props: AccordionDivProps) => {
  const {data} = props;
  const intl = useIntl()
  const dispatch = useDispatch();
  const formState = useSelector((state: RootState) => state.scopeSurveyForm);
  const countryList = useSelector((state: RootState) => state.masterList.countryList);
  const surveyInputData = useSelector((state: RootState) => state.profile.surveyInputData);
  const {data: profileDetails, refetch: refetchUserProfileData} = useGetProfileDetailsQuery()
  const [isValid, setIsValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  const [postSurvey, {isSuccess: isSuccessPostSurvey, isError: isErrorPostSurvey}] = usePostManualYearCumulativeSurveyMutation()

  useEffect(() => {
    if (profileDetails) {
      dispatch(updateProfile(profileDetails))
    }
  }, [profileDetails])

  useEffect(() => {
    if (isSuccessPostSurvey) {
      dispatch(
        updateNotification({
          message: 'Office energy consumption details submitted successfully',
          type: NotificationType.SUCCESS
        })
      )
    }
  }, [isSuccessPostSurvey])

  useEffect(() => {
    if (isErrorPostSurvey) {
      dispatch(
        updateNotification({
          message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
          type: NotificationType.ERROR,
        })
      )
    }
  }, [isErrorPostSurvey])

  const handleSubmit = async () => {
    // Reset validation states
    setIsValid(true)
    setErrorMessage("")

    try {
      // Transform form data to API format
      const payload: ManualYearCumulativeSurveyRequestOptionalType = {
        // SETTING OFFICE ELECTRICITY CONSUMPTION DETAILS
        office_electricity_consumption_details: formState.officeDetails.map(office => ({
          geo_id: office.country?.id || 0,
          country: office.country,
          state: office.state,
          city: office.city,
          area_sq_ft: office.totalOfficeSquareFootage || 0,
          no_of_employees: office.numberOfEmployeesInOffice || 0,
          avg_wfh_days_per_week_per_employee: office.averageWFHDaysPerWeek || 0,
          is_igbc_certified: office.isIGBCCertified === 'yes',
          is_renewable_energy_sourced: office.isRenewableEnergySourced === 'yes'
        })).filter((office: any) => office.numberOfEmployeesInOffice !== 0),
        ...(
          surveyInputData?.employeeCommuteDetails ? {
            employee_commute_details: {
              no_of_company_owned_vehicles: surveyInputData?.employeeCommuteDetails?.noOfCompanyOwnedVehicles || 0,
              avg_km_per_day_per_vehicle: surveyInputData?.employeeCommuteDetails?.avgKmPerDayPerVehicle || 0,
              no_of_employee_using_pvt_vehicles: surveyInputData?.employeeCommuteDetails?.noOfEmployeeUsingPvtVehicles || 0,
              avg_km_per_employee_per_day: surveyInputData?.employeeCommuteDetails?.avgKmPerEmployeePerDay || 0
            }
          } : {}
        ),
        ...(
          surveyInputData?.lightTravelerDetails ? {
            light_traveler_details: {
              no_of_employee_in_light_travels: surveyInputData?.lightTravelerDetails?.noOfEmployeeInLightTravels || 0,
              local_avg_round_trip_per_person_per_quarter: surveyInputData?.lightTravelerDetails?.localAvgRoundTripPerPersonPerQuarter || 0,
              local_travel_class: String(surveyInputData?.lightTravelerDetails?.localTravelClass || 'Economy'),
              medium_avg_round_trip_per_person_per_quarter: surveyInputData?.lightTravelerDetails?.mediumAvgRoundTripPerPersonPerQuarter || 0,
              medium_travel_class: String(surveyInputData?.lightTravelerDetails?.mediumTravelClass || 'Economy'),
              long_avg_round_trip_per_person_per_quarter: surveyInputData?.lightTravelerDetails?.longAvgRoundTripPerPersonPerQuarter || 0,
              long_travel_class: String(surveyInputData?.lightTravelerDetails?.longTravelClass || 'Economy')
            }
          } : {}
        ),
        ...(
          surveyInputData?.heavyTravelerDetails ? {
            heavy_traveler_details: {
              no_of_employee_in_heavy_travels: surveyInputData?.heavyTravelerDetails?.noOfEmployeeInHeavyTravels || 0,
              local_avg_round_trip_per_person_per_quarter: surveyInputData?.heavyTravelerDetails?.localAvgRoundTripPerPersonPerQuarter || 0,
              local_travel_class: String(surveyInputData?.heavyTravelerDetails?.localTravelClass || 'Economy'),
              medium_avg_round_trip_per_person_per_quarter: surveyInputData?.heavyTravelerDetails?.mediumAvgRoundTripPerPersonPerQuarter || 0,
              medium_travel_class: String(surveyInputData?.heavyTravelerDetails?.mediumTravelClass || 'Economy'),
              long_avg_round_trip_per_person_per_quarter: surveyInputData?.heavyTravelerDetails?.longAvgRoundTripPerPersonPerQuarter || 0,
              long_travel_class: String(surveyInputData?.heavyTravelerDetails?.longTravelClass || 'Economy')
            }
          } : {}
        ),
      }

      const isValidForm = validateOfficeDetails(payload.office_electricity_consumption_details || [])

      if (!isValidForm.isValid) {
        setIsValid(false)
        setErrorMessage(isValidForm.errorMessage)
        return
      }

      // Submit the data
      const response = await postSurvey(payload).unwrap()
      await refetchUserProfileData()
      
    } catch (error) {
      console.error('Error submitting survey:', error)
      setIsValid(false)
      setErrorMessage("Failed to submit survey. Please try again.")
    }
  }

  const updateSectionStatus = (officeDetails: OfficeDetail[]) => {
    const isAnyFieldFilled = officeDetails.some(office => 
      office.country?.id || 
      office.totalOfficeSquareFootage !== undefined || 
      office.numberOfEmployeesInOffice !== undefined ||
      office.averageWFHDaysPerWeek !== undefined ||
      office.isIGBCCertified !== '' ||
      office.isRenewableEnergySourced !== ''
    );

    const areAllFieldsFilled = officeDetails.every(office => 
      office.country?.id && 
      office.totalOfficeSquareFootage !== undefined && 
      office.numberOfEmployeesInOffice !== undefined &&
      office.averageWFHDaysPerWeek !== undefined &&
      office.isIGBCCertified !== '' &&
      office.isRenewableEnergySourced !== ''
    );

    let status: SectionStatus = "not-started";
    // if (areAllFieldsFilled) {
    //   status = "completed"; 
    // } else 
    if (isAnyFieldFilled) {
      status = "in-progress";
    }

    dispatch(setSectionStatus({
      section: "officeElectricityStatus", 
      status
    }));
  };

  const handleFieldChange = async (index: number, field: keyof OfficeDetail, value: any) => {
    if (field === 'country') {
      const selectedCountry = countryList.find(country => country.mg_id === value);
      dispatch(setOfficeDetail({
        index,
        field, 
        data: { 
          id: selectedCountry.mg_id, 
          name: selectedCountry.mg_name 
        }
      }));

      try {
        const states = await getStateList(value);
        dispatch(setOfficeDetail({
          index,
          field: 'stateList',
          data: states.data
        }));
        dispatch(setOfficeDetail({
          index,
          field: 'state', 
          data: { id: null, name: '' }
        }));
        dispatch(setOfficeDetail({
          index,
          field: 'city', 
          data: { id: null, name: '' }
        }));
        dispatch(setOfficeDetail({
          index,
          field: 'cityList',
          data: []
        }));
      } catch (error) {
        console.error('Error fetching states:', error);
      }
    } else if (field === 'state') {
      const selectedState = formState.officeDetails[index].stateList.find(state => state.mg_id === value);
      dispatch(setOfficeDetail({
        index,
        field, 
        data: { 
          id: selectedState.mg_id, 
          name: selectedState.mg_name 
        }
      }));

      try {
        const cities = await getCityList(value);
        dispatch(setOfficeDetail({
          index,
          field: 'cityList',
          data: cities.data
        }));
        dispatch(setOfficeDetail({
          index,
          field: 'city', 
          data: { id: null, name: '' }
        }));
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    } else if (field === 'city') {
      const selectedCity = formState.officeDetails[index].cityList.find(city => city.mg_id === value);
      dispatch(setOfficeDetail({
        index,
        field, 
        data: { 
          id: selectedCity.mg_id, 
          name: selectedCity.mg_name 
        }
      }));
    } else {
      dispatch(setOfficeDetail({index, field, data: value}));
    }

    // Make copy of form state with updated field
    const updatedOfficeDetails = formState.officeDetails?.map((office, i) => {
      if (i === index) {
        return {
          ...office,
          [field]: data
        }
      }
      return office;
    });

    updateSectionStatus(updatedOfficeDetails);
  };

  const handleRemoveOffice = (index: number) => {
    dispatch(removeOffice(index));
    // Update status after removing office
    const updatedOfficeDetails = formState.officeDetails.filter((_, i) => i !== index);
    updateSectionStatus(updatedOfficeDetails);
  };

  return (
    <div className="accordion bordered-grey" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
      <div className="accordion-item" style={{border: 'none'}}>
        <h2 className="accordion-header py-2" id='oneSection'>
          <button 
            className="accordion-button collapsed fs-3 bold bg-white" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target='#panel-oneSection' 
            aria-expanded="false" 
            aria-controls='panel-oneSection'
            style={{borderWidth: '0px !important', borderColor: 'transparent !important'}}
          >
            <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{data?.title}</p>
          </button>
          <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
            <RenderStatusPill status={data?.status} />
          </div>
        </h2>
        <div id='panel-oneSection' className="accordion-collapse collapse" aria-labelledby='oneSection'>
          <div className="accordion-body">
            {formState.officeDetails?.map((office, index) => (
              <div key={`office-${office.country?.id || index}-${office.state?.id || index}-${office.city?.id || index}`} className="office-section mb-4">
                { 
                  index > 0 && (
                    <>
                      <div className="mb-8 position-relative">
                        <hr className="mx-8" color="#C1C1C1" />
                        <button 
                          type='button' 
                          className='remove-button-styles fs-2' 
                          style={{
                            position: 'absolute',
                            right: 15,
                            top: -15,
                            height: 30,
                            width: 30,
                            borderRadius: 15,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'black',
                            backgroundColor: '#E4DFDF',
                            border: 'none',
                            padding: 5,
                            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                            fontWeight: 600
                          }} 
                          onClick={() => handleRemoveOffice(index)}
                          >
                          <p className="m-0">x</p>
                        </button>
                      </div>
                      <h4 className="mb-0 mx-8">OFFICE {index + 1}</h4>
                    </>
                  )
                }
                <div className='row px-8 py-6'>
                  <div className='col-4'>
                    <Dropdown
                      label="Country *"
                      options={countryList?.filter((country: any) => ['IND', 'USA', 'CAN'].includes(country.mg_code)).map(country => ({
                        label: country.mg_name,
                        value: country.mg_id
                      }))}
                      onChange={(v) => handleFieldChange(index, 'country', v)}
                      selectedValue={office.country?.id}
                      placeholder='Select Country'
                    />
                  </div>
                  <div className='col-4'>
                    <Dropdown
                      label="State"
                      options={office.stateList?.map(state => ({
                        label: state.mg_name,
                        value: state.mg_id
                      }))}
                      onChange={(v) => handleFieldChange(index, 'state', v)}
                      selectedValue={office.state?.id}
                      disabled={!office.country?.id}
                      placeholder='Select State'
                    />
                  </div>
                  <div className='col-4'>
                    <Dropdown
                      label="City"
                      options={office.cityList?.map(city => ({
                        label: city.mg_name,
                        value: city.mg_id
                      }))}
                      onChange={(v) => handleFieldChange(index, 'city', v)}
                      selectedValue={office.city?.id}
                      disabled={!office.state?.id}
                      placeholder='Select City'
                    />
                  </div>
                </div>
                <div className='row px-8 py-6'>
                  <div className='col-4'>
                    <InputNumber
                      label="Total Office Square Footage *"
                      value={office.totalOfficeSquareFootage}
                      onChange={(v) => handleFieldChange(index, 'totalOfficeSquareFootage', v)}
                      placeholder='Enter Square Footage'
                    />
                  </div>
                  <div className='col-4'>
                    <InputNumber
                      label="Number of Employees In Office *"
                      value={office.numberOfEmployeesInOffice}
                      onChange={(v) => handleFieldChange(index, 'numberOfEmployeesInOffice', v)}
                      placeholder='Enter Number Of Employees'
                    />
                  </div>
                  <div className='col-4'>
                    <InputNumber
                      label="Average WFH Days Per Employee Per Week"
                      value={office.averageWFHDaysPerWeek}
                      onChange={(v) => handleFieldChange(index, 'averageWFHDaysPerWeek', v)}
                      placeholder='Enter Average Days'
                    />
                  </div>
                </div>
                <div className='row px-8 py-6'>
                  <div className='col-4'>
                    <Dropdown
                      label="Is The Office LEED Gold Certified"
                      options={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
                      onChange={(v) => handleFieldChange(index, 'isLEEDGoldCertified', v)}
                      selectedValue={office.isLEEDGoldCertified}
                      placeholder='Select Yes/No'
                    />
                  </div>
                  <div className='col-4'>
                    <Dropdown
                      label="Is The Office LEED Platinum Certified"
                      options={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
                      onChange={(v) => handleFieldChange(index, 'isLEEDPlatinumCertified', v)}
                      selectedValue={office.isLEEDPlatinumCertified}
                      placeholder='Select Yes/No'
                    />
                  </div>
                  <div className='col-4'>
                    <Dropdown
                      label="Is The Office IGBC Certified"
                      options={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
                      onChange={(v) => handleFieldChange(index, 'isIGBCCertified', v)}
                      selectedValue={office.isIGBCCertified}
                      placeholder='Select Yes/No'
                    />
                  </div>
                </div>
                <div className='row px-8 py-6'>
                  <div className='col-4'>
                    <Dropdown
                      label="Is Renewable Energy Sourced"
                      options={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
                      onChange={(v) => handleFieldChange(index, 'isRenewableEnergySourced', v)}
                      selectedValue={office.isRenewableEnergySourced}
                      placeholder='Select Yes/No'
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className='row px-8 py-6'>
              <div className='col-12 d-flex justify-content-end'>
                {!isValid && (
                  <p className="text-danger mt-2 mb-0 mx-6" style={{fontSize: '14px'}}>
                    {errorMessage}
                  </p>
                )}
                <button 
                  type='button'
                  className='remove-button-styles fs-5'
                  style={{borderRadius: 10, color: 'black', backgroundColor: 'white', border: '1px solid #E8E7E7', padding: '5px 20px', boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)', fontWeight: 500}}
                  onClick={() => dispatch(addNewOffice())}
                >
                  + Add Office
                </button>
                <button
                  type='button'
                  className='remove-button-styles fs-5'
                  style={{
                    borderRadius: 10,
                    color: 'white',
                    backgroundColor: '#0491BD',
                    border: 'none',
                    padding: '8px 30px',
                    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                    fontWeight: 500,
                    marginLeft: 10
                  }}
                  onClick={handleSubmit}
                  >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccordionDivOne
