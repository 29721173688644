import { ManualYearCumulativeSurveyRequestType } from "../store/type";

export const validateSurveyQuestions = (formState: ManualYearCumulativeSurveyRequestType): { isValid: boolean, errorMessage: string } => {
  // Validate office details
  const isOfficeDetailsValid = formState.office_electricity_consumption_details.every((office: any) => 
    office.geo_id && 
    office.area_sq_ft !== undefined && 
    office.no_of_employees !== undefined &&
    office.avg_wfh_days_per_week_per_employee !== undefined &&
    office.is_igbc_certified !== undefined &&
    office.is_renewable_energy_sourced !== undefined
  );
  if (!isOfficeDetailsValid) {
    return {
      isValid: false,
      errorMessage: "Please complete office energy consumption details"
    };
  }

  // Validate employee commute details
  if (formState.employee_commute_details.no_of_company_owned_vehicles === undefined ||
      formState.employee_commute_details.avg_km_per_day_per_vehicle === undefined ||
      formState.employee_commute_details.no_of_employee_using_pvt_vehicles === undefined ||
      formState.employee_commute_details.avg_km_per_employee_per_day === undefined) {
    return {
      isValid: false,
      errorMessage: "Please complete employee office commute details"
    };
  }

  // Validate light traveler details
  if (formState.light_traveler_details.no_of_employee_in_light_travels === undefined ||
      formState.light_traveler_details.local_avg_round_trip_per_person_per_quarter === undefined ||
      formState.light_traveler_details.medium_avg_round_trip_per_person_per_quarter === undefined ||
      formState.light_traveler_details.long_avg_round_trip_per_person_per_quarter === undefined ||
      formState.light_traveler_details.local_travel_class === '' ||
      formState.light_traveler_details.medium_travel_class === '' ||
      formState.light_traveler_details.long_travel_class === '') {
    return {
      isValid: false,
      errorMessage: "Please complete professional air commute - light traveler details"
    };
  }

  // Validate heavy traveler details
  if (formState.heavy_traveler_details.no_of_employee_in_heavy_travels === undefined ||
      formState.heavy_traveler_details.local_avg_round_trip_per_person_per_quarter === undefined ||
      formState.heavy_traveler_details.local_travel_class === '' ||
      formState.heavy_traveler_details.medium_avg_round_trip_per_person_per_quarter === undefined ||
      formState.heavy_traveler_details.medium_travel_class === '' ||
      formState.heavy_traveler_details.long_avg_round_trip_per_person_per_quarter === undefined ||
      formState.heavy_traveler_details.long_travel_class === '') {
    return {
      isValid: false,
      errorMessage: "Please complete professional air commute - heavy traveler details"
    };
  }

  return {
    isValid: true,
    errorMessage: ""
  };
}

export const validateOfficeDetails = (officeDetails: ManualYearCumulativeSurveyRequestType['office_electricity_consumption_details'] | undefined): { isValid: boolean, errorMessage: string } => {
  let errorMessage = 'Please complete office energy consumption details'
  if (!officeDetails) {
    return {
      isValid: false,
      errorMessage
    }
  }
  const isValid = officeDetails.every((office) => {
    if (!office.geo_id) {
      errorMessage = 'Please select a country'
      return false
    }
    if (!office.area_sq_ft || office.area_sq_ft < 50) {
      errorMessage = 'Please enter valid office square footage'
      return false
    }
    if (!office.no_of_employees) {
      errorMessage = 'Please enter valid number of employees'
      return false
    }
    return true
  });

  return {
    isValid,
    errorMessage: isValid ? "" : errorMessage
  };
}

export const validateEmployeeCommute = (employeeCommute: ManualYearCumulativeSurveyRequestType['employee_commute_details'] | undefined): { isValid: boolean, errorMessage: string } => {
  let errorMessage = 'Please complete employee office commute details'
  let isValid = false
  if (!employeeCommute) {
    return {
      isValid,
      errorMessage
    }
  }
  const fieldOne = employeeCommute.no_of_company_owned_vehicles
  const fieldTwo = employeeCommute.avg_km_per_day_per_vehicle
  const fieldThree = employeeCommute.no_of_employee_using_pvt_vehicles
  const fieldFour = employeeCommute.avg_km_per_employee_per_day
  
  if (fieldOne && !fieldTwo) {
    errorMessage = 'Please enter valid average km per day per vehicle'
    isValid = false
  }
  if (fieldTwo && !fieldOne) {
    errorMessage = 'Please enter valid number of company owned vehicles'
    isValid = false
  }
  if (fieldThree && !fieldFour) {
    errorMessage = 'Please enter valid average km per employee per day'
    isValid = false
  }
  if (fieldFour && !fieldThree) {
    errorMessage = 'Please enter valid number of employees using private vehicles'
    isValid = false
  }
  if ((fieldOne && fieldTwo) || (fieldThree && fieldFour)) {
    return {
      isValid: true,
      errorMessage: ""
    }
  }

  return {
    isValid,
    errorMessage: isValid ? "" : errorMessage
  };
}

export const validateLightTraveler = (lightTraveler: ManualYearCumulativeSurveyRequestType['light_traveler_details'] | undefined): { isValid: boolean, errorMessage: string } => {
  let errorMessage = 'Please complete professional air commute - light traveler details'
  let isValid = true
  if (!lightTraveler) {
    return {
      isValid,
      errorMessage
    }
  }

  if (!lightTraveler.no_of_employee_in_light_travels) {
    errorMessage = 'Please enter valid number of employees in light travels'
    isValid = false
  }
  else if (!lightTraveler.local_avg_round_trip_per_person_per_quarter && !lightTraveler.medium_avg_round_trip_per_person_per_quarter && !lightTraveler.long_avg_round_trip_per_person_per_quarter) {
    errorMessage = 'Please enter valid average round trip per person per quarter for local travel or medium travel or long travel'
    isValid = false
  }

  return {
    isValid,
    errorMessage: isValid ? "" : errorMessage
  };
}

export const validateHeavyTraveler = (heavyTraveler: ManualYearCumulativeSurveyRequestType['heavy_traveler_details'] | undefined): { isValid: boolean, errorMessage: string } => {
  let errorMessage = 'Please complete professional air commute - heavy traveler details'
  let isValid = true
  if (!heavyTraveler) {
    return {
      isValid,
      errorMessage
    }
  }

  if (!heavyTraveler.no_of_employee_in_heavy_travels) {
    errorMessage = 'Please enter valid number of employees in heavy travels'
    isValid = false
  }
  else if (!heavyTraveler.local_avg_round_trip_per_person_per_quarter && !heavyTraveler.medium_avg_round_trip_per_person_per_quarter && !heavyTraveler.long_avg_round_trip_per_person_per_quarter) {
    errorMessage = 'Please enter valid average round trip per person per quarter for local travel or medium travel or long travel'
    isValid = false
  }

  return {
    isValid,
    errorMessage: isValid ? "" : errorMessage
  };
}

