import { useSelector } from 'react-redux'
import { useState } from 'react'
import FuelEmissionTable from './components/fuel-emission-table'
import { RootState } from '../../store/store'
import LockWrapper from '../../../_metronic/partials/components/lock-wrapper/LockWrapper'
import { Dropdown } from '../../ui/components/dropdown'

const fuelEmissionData = [
  {
    emissionSource: 'Fuel Consumption In Company-Owned Vehicles',
    emissions: 0,
    savedEmissions: 0,
  },
  {
    emissionSource: 'Diesel Consumption In DG Sets',
    emissions: 0,
    savedEmissions: 0,
  },
  {
    emissionSource: 'Fire Extinguishers',
    emissions: 0,
    savedEmissions: 0,
  }
]

const locationOptions = [
  { label: 'All', value: 'all' },
  { label: 'Bangalore', value: 'bangalore' },
  { label: 'Mumbai', value: 'mumbai' },
  { label: 'Delhi', value: 'delhi' },
  { label: 'Chennai', value: 'chennai' },
  { label: 'Hyderabad', value: 'hyderabad' }
]

const FuelEmissionPage = () => {
  const {lockedScreens} = useSelector((state: RootState) => state.profile)
  const [selectedLocation, setSelectedLocation] = useState('all')

  const handleLocationChange = (value: string) => {
    setSelectedLocation(value)
  }

  return (
    <LockWrapper isLocked={false} description='This page displays information about Fuel emissions and its details. For more details, contact support. '>
      <div className="blank-page-view pt-8 pb-16 px-6">
        <div className='p-0 m-0 d-flex justify-content-between align-items-end'>
          <h1 className='m-0' style={{fontWeight: 600}}>FUEL EMISSIONS</h1>
          <div className='d-flex align-items-center'>
            <h4 className='m-0' style={{color: 'black', fontWeight: 600, paddingRight: 20, paddingTop: 10}}>Location </h4>
            <Dropdown
              placeholder='Select Location'
              label=''
              options={locationOptions}
              selectedValue={selectedLocation}
              onChange={handleLocationChange}
              dropdownStyle={{fontSize: 14, borderColor: '#bababa', color: 'black'}}
              containerStyle={{ width: 200, height: 40 }}
              disablePlaceholder
            />
          </div>
        </div>

        <div className='mt-8'>
          <FuelEmissionTable listData={fuelEmissionData} />
        </div>
      </div>
    </LockWrapper>
  )
}

export default FuelEmissionPage
